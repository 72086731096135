import React, { createContext, useState, useContext } from "react";

const RoleContext = createContext();

console.log('===Jai Test Role Context', RoleContext)

export const RoleProvider = ({ children }) => {
    const [role, setRole] = useState(null);

    const setRoleContext = (newRole) => {
        setRole(newRole);
        //setRole(1);
    };

    return (
        <RoleContext.Provider value={{ role, setRole: setRoleContext }}>
            {children}
        </RoleContext.Provider>
    );
};

export const useRole = () => {
    return useContext(RoleContext);
};
