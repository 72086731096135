import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../App.css";

function LicenseValidator() {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const OWNER_SERVER_API = `${apiUrl}/license-key`;

    const [licenseKey, setLicenseKey] = useState('');
    const [message, setMessage] = useState('');

    const handleValidate = async () => {
       // alert(window.location.hostname)
        try {
            const response = await axios.post(OWNER_SERVER_API, {
                license_key: licenseKey,
                client_domain: window.location.hostname,
            });
            setMessage(response.data.message);
            localStorage.setItem("license_valid", JSON.stringify({ valid: true, expiresAt: response.data.expiry_date }));
            
        } catch (error) {
            setMessage(error.response.data.error || 'An error occurred');
        }
    };

    return (
            <div className="validation-container">
                        <h2>License Key Validation</h2>
                        <input
                            type="text"
                            value={licenseKey}
                            onChange={(e) => setLicenseKey(e.target.value)}
                            placeholder="Enter your license key"
                            className="input-field"
                        />
                        <button onClick={handleValidate} className="validate-button">
                            Validate & Activate
                        </button>
                        {message && <p className="message">{message}</p>}
                    </div>


                );
            }

            export default LicenseValidator;
