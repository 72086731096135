// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState(() => localStorage.getItem('authToken'));
  const [profileImg, setProfileImg] = useState(() => localStorage.getItem('profile_img'));

  // Function to refresh token if your auth system supports it
  const refreshToken = async () => {
    
    //alert('Auth Context here');
    
    try {
      // Call your API to refresh the token
      const response = await fetch('/refresh-token', {
        method: 'POST',
        credentials: 'include', // If using cookies
      });
      const data = await response.json();

      if (data.token) {
        // Update the token and expiration time
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('tokenExpiration', Date.now() + data.expiresIn * 1000);
        setAuthToken(data.token);
      } else {
        logout(); // If refresh fails, log out the user
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
    }
  };
  
  useEffect(() => {
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    if (tokenExpiration && Date.now() > tokenExpiration - 60000) { // Refresh 1 minute before expiration
      refreshToken();
    }
  }, []);

  const login = (token, img) => {
    localStorage.setItem('authToken', token);
    localStorage.setItem('profile_img', img); // Store profile_img in localStorage
    setAuthToken(token);
    setProfileImg(img);
  };

  const logout = () => {
    //alert('Auth logout')
    localStorage.removeItem('authToken');
    localStorage.removeItem('profile_img');
    localStorage.removeItem('tokenExpiration');
    setAuthToken(null);
    setProfileImg(null);
    navigate('/');
  };

  // Optional: Handle auto-logout on token expiration
  useEffect(() => {
    if (authToken) {
      const tokenExpiration = localStorage.getItem('tokenExpiration');
      if (tokenExpiration) {
        const timeout = tokenExpiration - Date.now() - 60000; // 1 minute before expiration
        if (timeout > 0) {
          const timer = setTimeout(() => {
            refreshToken();
          }, timeout);
          return () => clearTimeout(timer);
        } else {
          refreshToken();
        }
      }
    }
  }, [authToken]);

  return (
    <AuthContext.Provider value={{ authToken, profileImg, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
