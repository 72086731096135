import React, { useState, useEffect } from 'react';
import { useRole } from "../context/RoleContext";
import axios from 'axios';

const AccessControl = ({ children, moduleId, requiredAccess }) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    
    //const { role } = useRole();
    const obj = { role: "1" };   // Note: 1 is Hardcode need to removed 
    const { role } = obj; // This works
    const [permissions, setPermissions] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch permissions dynamically from the API
    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${apiUrl}/role-access/1`, { params: { role, moduleId } });
                setPermissions(response.data); // Assume API returns an array of permissions for the role
            } catch (error) {
                console.error("Error fetching permissions:", error);
            } finally {
                setLoading(false);
            }
        };
     
        //alert(role)

        if (role) {
            fetchPermissions();
        }
    }, [role, moduleId]);

    // While loading permissions
    if (loading) {
        return <p>Loading...</p>;
    }

    // Check if the user has the required access
    const hasAccess = () => {
        //return permissions?.includes(requiredAccess);
          return permissions;
    };

    return hasAccess() ? (
        children
    ) : (
        <p>Access Denied 3333</p>
    );

    
};

export default AccessControl;
