import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import Papa from 'papaparse'; // For parsing CSV files
import Header from './Header'
import { Formik, useFormik, Form, Field, errors } from 'formik';
import Select from 'react-select';

import * as Yup from 'yup';


import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';

import $ from 'jquery';
import 'datatables.net-bs4';


function ProductsCSV() {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    // a local state to store the currently selected file.
    const [selectedYear, setSelectedYear] = useState(''); // State for selected year
    const currentYear = new Date().getFullYear(); // Get the current year
    const startYear = 1980; // Define the starting year

     // Generate an array of years from startYear to currentYear
  const years = [];
  for (let i = currentYear; i >= startYear; i--) {
    years.push(i);
  }

  // Handle change in the dropdown
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };


    const [csvData, setCsvData]                 = useState([]);
    const [totalCount, setTotalCount]           = useState(0);
    const [data, setData]                       = useState([]);

    //const [editContact, setEditContact]        = useState([]);
    const [editProducts, setEditProducts]        = useState([]);

    const [selectedState, setSelectedState]      = useState('');
    const [countries, setCountries]              = useState([]);
    const [selectedCountry, setSelectedCountry]  = useState('');
    const [states, setStates]                    = useState([]);
    const [cities, setCities]                    = useState([]);
    const [isVisible, setIsVisible]              = useState(false);




    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    setCsvData(results.data);
                }
            });
        }
    };

    const sendDataToApi = () => {
        console.log(csvData);

        axios.post(`${apiUrl}/product-data-csv`, csvData)
            .then(response => {
                pageLoadData();
                console.log('Data sent successfully:', response.data);
            })
            .catch(error => {
                console.error('Error sending data:', error);
            });
    };

    const sendPublishFinalDataToApi = () => {
        console.log(csvData);

        const userConfirmed = window.confirm("Do you want to publish the completed data?");

        if (userConfirmed) {
                            try {
                                axios.post(`${apiUrl}/publish-product-csv-data`, csvData)
                                .then(response => {
                                    pageLoadData();
                                    console.log('Data sent successfully:', response.data);
                                })
                                .catch(error => {
                                    console.error('Error sending data:', error);
                                });

            } catch (error) {
                console.error('Error performing action:', error);
            }
        }


        
    };



    useEffect(() => {
             pageLoadData();
    }, []);


  const pageLoadData = async () => {
        axios.post(`${apiUrl}/product-tmp-csv-list`)
            .then(response => {
                //alert("111111111");
                if (Array.isArray(response.data.data)) {
                    //alert("asd2222222222222222asd");
                    setData(response.data.data);
                    setTotalCount(response.data.data.length); // Get the total count

                    if (!$.fn.DataTable.isDataTable('#categorieslist_data')) {
                        $('#categorieslist_data').DataTable({
                            data: response.data.data,

                            columns: [
                                { title: "Sr.No", data: "pk_product_id" },
                                { title: "Product Name", data: "product_name" },
                                { title: "Model Number", data: "model_number" },
                                { title: "Machine Type", data: "machine_type" },
                                { title: "Year Of Manufacture", data: "year_of_manufacture" },
                                { title: "Product Conditions", data: "product_conditions" },
                                { title: "Machine Description", data: "machine_description" },
                                { title: "Manufacturer", data: "manufacturer" },
                                { title: "Serial Number", data: "serial_number" },
                                { title: "Specifications", data: "specifications" },
                                {
                                    title: "Edit",
                                    data: "null",
                                    render: function (data, type, row, meta) {
                                       
                                        return `<div class="dropdown table-action">
                                        <a href="#" class="action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right edit-btn" style=""><a class="dropdown-item edit-popup" href="javascript:void(0);" data-id="${row.pk_product_id}"><i class="ti ti-edit text-blue"></i> Edit</a>
                                        </div></div>`;
                                    }
                                },
                            
                            ],
                            order: [[0, 'desc']] // Order by the first column (Full Name) in descending order


                        });
                    } else {
                        const table = $('#categorieslist_data').DataTable();
                        table.clear().rows.add(response.data.data).draw(); // Update table data
                    }


                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the contact list data!", error);
            });

    };

    $("#categorieslist_data_wrapper").off().on("click", '.edit-popup', function () {
        alert('edit id')
        $('.toggle-popup1').addClass('sidebar-popup');
        $('.sidebar-popoverlay').addClass('open');
        const id = $(this).attr('data-id');
        alert(id)
       handleEdit(id);
        return false;
    });


    const handleEdit = async (id) => {
        alert(`Edit button clicked for ID: ${id}`);
        if (id) {
            await axios({
                method: 'POST',
                url: `${apiUrl}/product-edit-tmp`,
                data: { id: `${id}` }
            })
                .then(function (response) {
                    //alert(response.data.data.first_name);
                    //setEditContact(response.data.data);
                    setEditProducts(response.data.data);

                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    };


    
    useEffect(() => {
       
        if (editProducts) {
           
            
            edit_formik.setValues({
                edit_pk_product_id: editProducts.pk_product_id || '',
                edit_pk_product_id: editProducts.pk_product_id || '',
                edit_product_name: editProducts.product_name || '',
                edit_model_number: editProducts.model_number || '',
                edit_machine_type: editProducts.machine_type || '',
                edit_year_of_manufacture: editProducts.year_of_manufacture || '',
                edit_product_conditions: editProducts.product_conditions || '',
                edit_machine_description: editProducts.machine_description || '',
                edit_manufacturer: editProducts.manufacturer || '',
                edit_serial_number: editProducts.serial_number || '',
                edit_specifications: editProducts.specifications || '',
                edit_price: editProducts.price || '',
                edit_payment_terms: editProducts.payment_terms || '',
                edit_discounts_and_offers: editProducts.discounts_and_offers || '',
                edit_location_of_the_machine: editProducts.location_of_the_machine || '',
                edit_shipping_options: editProducts.shipping_options || '',

                edit_shipping_cost: editProducts.shipping_cost || '',
                edit_installation_services: editProducts.installation_services || '',
                edit_negotiable_price: editProducts.negotiable_price || '',
                edit_support_and_maintenance: editProducts.support_and_maintenance || '',

            });
        }
    }, [editProducts]);


    // Validation schema using Yup
    const edit_schema = Yup.object({
        edit_product_name: Yup.string().min(3).required('Product name is required'),
        edit_model_number: Yup.string().required('Model number is required'),
   });

    
// Formik setup
   // Formik setup
   const edit_formik = useFormik({
    initialValues: {
        edit_pk_product_id                  : '',
        edit_pk_product_id                  : '',
        edit_product_name                   : '',
        edit_model_number                   : '',
        edit_product_id                     : '',
        edit_machine_type                   : '',
        edit_year_of_manufacture            : '',
        edit_product_conditions             : '',
        edit_machine_description            : '',
        edit_manufacturer                   : '',
        edit_specifications                 : '',
        edit_serial_number                  : '',
        edit_price                          : '',
        edit_payment_terms                  : '',
        edit_discounts_and_offers           : '',
        edit_location_of_the_machine        : '',
        edit_shipping_cost                  : '',
        edit_installation_services          : '',
        edit_negotiable_price               : '',
        edit_support_and_maintenance        : '',
        edit_shipping_options               : '',
        

    },
    validationSchema: edit_schema,
    onSubmit: (values) => {
        console.log('Form submitted!');  

        const formData = new FormData();
        formData.append("edit_pk_product_id", values.edit_pk_product_id);
        formData.append("edit_product_name", values.edit_product_name);
        formData.append("edit_model_number", values.edit_model_number);
        formData.append("edit_machine_type", values.edit_machine_type);
        //formData.append("edit_product_id", values.edit_product_id);
        
        formData.append("edit_year_of_manufacture", values.edit_year_of_manufacture);
        formData.append("edit_product_conditions", values.edit_product_conditions);
        formData.append("edit_machine_description", values.edit_machine_description);
        formData.append("edit_manufacturer", values.edit_manufacturer);
        formData.append("edit_serial_number", values.edit_serial_number);
        formData.append("edit_specifications", values.edit_specifications);
        formData.append("edit_price", values.edit_price);
        formData.append("edit_payment_terms", values.edit_payment_terms);
        formData.append("edit_discounts_and_offers", values.edit_discounts_and_offers);
        formData.append("edit_location_of_the_machine", values.edit_location_of_the_machine);
        formData.append("edit_shipping_cost", values.edit_shipping_cost);
        formData.append("edit_installation_services", values.edit_installation_services);
        formData.append("edit_negotiable_price", values.edit_negotiable_price);
        formData.append("edit_support_and_maintenance", values.edit_support_and_maintenance);
        formData.append("edit_shipping_options", values.edit_shipping_options);
        
       

        axios({
            method: 'POST',
            url: `${apiUrl}/product-csv-update`,
            headers: { "Content-Type": "multipart/form-data" },
            //data: values
            data: formData,
        })
            .then(function (res) {
                console.log(res)
                alert('Data was successfully updated.');
                $('.toggle-popup1').removeClass('sidebar-popup');
                $('.sidebar-popoverlay').removeClass('open');
                editDataTableRow(values.edit_pk_product_id, res.data.data)
                // $("#preview1").show();
                setIsVisible(!isVisible); // Toggle visibility
            })
            .catch(function (res) {
                console.log(res,)
            });

    },
});


    const editDataTableRow = (id, updatedData) => {
        // Get the DataTable instance
        const table = $('#categorieslist_data').DataTable();

        // Find the row that matches the id and update it
        const rowIndex = table.rows().eq(0).filter(function (idx) {
            return table.cell(idx, 0).data() == id; // Assumes first column is the ID
        });

        if (rowIndex.length > 0) {
            table.row(rowIndex).data(updatedData).draw(false); // Update the row data without pagination reset
        }
    };

    

    const deleteTempData = () => {
        const userConfirmed = window.confirm("Are you sure you want to delete this list?");

        if (userConfirmed) {
                            try {
                                axios.post(`${apiUrl}/delete-product-data`, csvData)
                                .then(response => {
                                    pageLoadData();
                                    console.log('Data sent successfully:', response.data);
                                })
                                .catch(error => {
                                    console.error('Error sending data:', error);
                                });

            } catch (error) {
                console.error('Error performing action:', error);
            }
        }
  };


  const options = [
    { value: 'Cat', label: 'Call' },
    { value: 'Dog', label: 'Dog' },
    { value: 'Others', label: 'Others' },
    { value: 'None', label: 'None' },
];




const setInputValueEdit = useCallback(
    (key, value) =>
        edit_formik.setValues({
            ...edit_formik.values,
            [key]: value,
        }),
    [edit_formik]
);



    return (


        <div className="main-wrapper">

            {/* Header Start  */}
            <Header />
            {/* Header End  */}

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                           
                            <div className="page-header">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <h4 className="page-title">Import Products Data<span className="count-title">{totalCount}</span></h4>
                                    </div>
                                    <div className="col-4">
                                        <h4 className="page-title">Download Products Sample File <a href="./assets/downloads/products-data-csv.csv"> <i class="fa-solid fa-download"></i></a></h4>
                                    </div>

                                    <div className="col-4 text-end">
                                        <div className="head-icons">
                                            <a href="contacts.html" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
                                            <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Collapse" id="collapse-header"><i className="ti ti-chevrons-up"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="card main-card">
                                <div className="card-body">

                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <label class="col-form-label">Attachment <span class="text-danger">*</span></label>
                                            <input type="file" accept=".csv" onChange={handleFileUpload} />
                                            <button class="btn btn-primary" onClick={sendDataToApi}>Send Data</button>

                                            {/* <div class="mb-3">
                                            <label class="col-form-label">Attachment <span class="text-danger">*</span></label>
                                            <div class="drag-attach">
                                                <input type="file" accept=".csv" onChange={handleFileUpload} />
                                                <div class="img-upload">
                                                    <i class="ti ti-file-broken"></i>Upload File
                                                </div>
                                                <button type="button" onClick={sendDataToApi} class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#create_success">Create</button>
                                            </div>
                                        </div> */}
                                        </div>

                                        {/* <div className="col-6">
                                            <button class="btn btn-info" onClick={sendPublishFinalDataToApi}>Publish Final Data After Review</button>
                                        </div> */}
                                    </div>

                                    
                                <div className="table-responsive custom-table pipeline-listing mt-5">
                                <button className="btn btn-info" style={{ marginRight : 5 }} onClick={sendPublishFinalDataToApi}>Publish Final Data After Review</button>
                                 <button className="btn btn-primary" onClick={deleteTempData}> Delete All Data </button>

                                        <table className="table table-striped table-bordered" id="categorieslist_data" style={{ width: "100%" }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className="no-sort">
                                                        <label className="checkboxs">
                                                        <input type="checkbox" id="select-all" /><span className="checkmarks"></span></label>
                                                    </th>
                                                    <th className="no-sort"></th>
                                                    <th>Name</th>
                                                    <th>Phone</th>
                                                    <th>Email</th>
                                                    <th>Tags</th>
                                                    <th>Location</th>
                                                    <th>Rating</th>
                                                    <th>Status</th>
                                                    <th className="text-end">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="datatable-length"></div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="datatable-paginate"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






            <div className="toggle-popup1">
                <div className="sidebar-layout">
                    <div className="sidebar-header">
                        <h4>Edit Product</h4>
                        <a href="#" className="sidebar-close1 toggle-btn"><i className="ti ti-x"></i></a>
                    </div>
                    <div className="toggle-body">
                        <div className="pro-create">
 
                         
                        <form onSubmit={edit_formik.handleSubmit}>
                                <input type="hidden1" name="edit_pk_product_id" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_pk_product_id} />

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="col-form-label">Product Name <span className="text-danger">*</span></label>
                                            <input className="form-control" name="edit_product_name" value={edit_formik.values.edit_product_name} onChange={(e) => setInputValueEdit("edit_product_name", e.target.value)} />
                                            <small className="error">{edit_formik.errors.edit_product_name}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label className="col-form-label">Model Number <span className="text-danger">*</span></label>
                                            </div>
                                            <input className="form-control" name="edit_model_number" value={edit_formik.values.edit_model_number} onChange={(e) => setInputValueEdit("edit_model_number", e.target.value)} />
                                            <small className="error">{edit_formik.errors.edit_model_number}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Machine Type </label>
                                            <input className="form-control" name="edit_machine_type" value={edit_formik.values.edit_machine_type} onChange={(e) => setInputValueEdit("edit_machine_type", e.target.value)} />
                                        </div>
                                    </div>
                                   
                                 <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Year Of Manufacture </label>
                                           <select
                                                className="form-control"
                                                id="edit_year_of_manufacture"
                                                name="edit_year_of_manufacture" // Add the name attribute for Formik
                                                value={edit_formik.values.edit_year_of_manufacture} // Bind to Formik's value
                                                onChange={edit_formik.handleChange} // Use Formik's handleChange
                                        >
                                        <option value="">-- Select Year --</option>
                                        {years.map((year) => (
                                        <option key={year} value={year}>
                                        {year}
                                        </option>
                                        ))}
                                        </select>

                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Product Conditions</label>
                                            <input className="form-control" name="edit_product_conditions" value={edit_formik.values.edit_product_conditions} onChange={(e) => setInputValueEdit("edit_product_conditions", e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Machine Description</label>
                                            <input className="form-control" name="edit_machine_description" value={edit_formik.values.edit_machine_description} onChange={(e) => setInputValueEdit("edit_machine_description", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Manufacturer</label>
                                            <input className="form-control" name="edit_manufacturer" value={edit_formik.values.edit_manufacturer} onChange={(e) => setInputValueEdit("edit_manufacturer", e.target.value)} />
                                        </div>
                                    </div>
                                     
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Serial Number</label>
                                            <input className="form-control" name="edit_serial_number" value={edit_formik.values.edit_serial_number} onChange={(e) => setInputValueEdit("edit_serial_number", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Specifications</label>
                                            <input className="form-control" name="edit_specifications" value={edit_formik.values.edit_specifications} onChange={(e) => setInputValueEdit("edit_specifications", e.target.value)} />
                                        </div>
                                    </div>

                                   

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Price</label>
                                            <input className="form-control" name="edit_price" value={edit_formik.values.edit_price} onChange={(e) => setInputValueEdit("edit_price", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Payment Terms</label>
                                            <input className="form-control" name="edit_payment_terms" value={edit_formik.values.edit_payment_terms} onChange={(e) => setInputValueEdit("edit_payment_terms", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Discounts And Offers</label>
                                            <input className="form-control" name="edit_discounts_and_offers" value={edit_formik.values.edit_discounts_and_offers} onChange={(e) => setInputValueEdit("edit_discounts_and_offers", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Location Of The Machine</label>
                                            <input className="form-control" name="edit_location_of_the_machine" value={edit_formik.values.edit_location_of_the_machine} onChange={(e) => setInputValueEdit("edit_location_of_the_machine", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Shipping Options </label>
                                            <input className="form-control" name="edit_shipping_options" value={edit_formik.values.edit_shipping_options} onChange={(e) => setInputValueEdit("edit_shipping_options", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Shipping Cost<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_shipping_cost" value={edit_formik.values.edit_shipping_cost} onChange={(e) => setInputValueEdit("edit_shipping_cost", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Installation Services<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_installation_services" value={edit_formik.values.edit_installation_services} onChange={(e) => setInputValueEdit("edit_installation_services", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Negotiable Price<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_negotiable_price" value={edit_formik.values.edit_negotiable_price} onChange={(e) => setInputValueEdit("edit_negotiable_price", e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="col-form-label">Support And Maintenance<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_support_and_maintenance" value={edit_formik.values.edit_support_and_maintenance} onChange={(e) => setInputValueEdit("edit_support_and_maintenance", e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-button text-end">
                                    <a href="#" className="btn btn-light sidebar-close1">Cancel</a>
                                    <button type="submit" className="btn btn-primary">
                                        Update
                                    </button>

                                    {/* <a href="#" className="btn btn-primary">Save Changes</a> */}
                                </div>
                            </form>
                   
                            
                        </div>
                    </div>
                </div>
            </div>



</div>

    )
}

export default ProductsCSV;