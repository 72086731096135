// import React from 'react';
// import { useFormik } from 'formik';
// import Select from 'react-select';

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useHref, useNavigate, Navigate } from 'react-router-dom';
import { Formik, useFormik, Form, Field, errors } from 'formik';
import Select from 'react-select';

import * as Yup from 'yup';
import Header from './Header'
import { contactsValidation } from './contactsValidation';


//import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import $ from 'jquery';
import 'datatables.net-bs4';

import { differenceInYears } from 'date-fns';


function MultiSelectDropdown() {
    const formik = useFormik({
        initialValues: {
          edit_pet_type: [],
        },
        onSubmit: values => {
          console.log(values.edit_pet_type);
        },
      });

      const options = [
        { value: 'Cat', label: 'Call' },
        { value: 'Dog', label: 'Dog' },
        { value: 'Others', label: 'Others' },
        { value: 'None', label: 'None' },
      ];


  return (

    <form onSubmit={formik.handleSubmit}>
      <Select class="multiple-img"
        id="edit_pet_type"
        name="edit_pet_type"
        options={options}
        isMulti
        value={options.filter(option => formik.values.edit_pet_type.includes(option.value))}
        onChange={(selectedOptions) => {
          formik.setFieldValue('edit_pet_type', selectedOptions.map(option => option.value));
        }}
      />
      <button type="submit">Submit</button>
    </form>
   
  );
}

export default MultiSelectDropdown;
