import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Home = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); 
  const [message, setMessage] = useState('');
  const [licenseValid, setLicenseValid] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    // Load saved credentials from localStorage
    const savedEmail = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('password');
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);
  

  useEffect(() => {
   const validateLicenseKey = async () => {
     try {
       const response = await axios.post(`${apiUrl}/validate-license-key`, {
         client_domain: window.location.hostname,
       });
 
       if (response.status === 200) {
         // Check if license is active or not
         const message = response.data.message;
         
         if (message === "License is already active") {
           // If the license is valid
           setLicenseValid(true);
           setMessage('');
           
           // Optionally, store the license validity in localStorage
           localStorage.setItem('license_valid', JSON.stringify({ valid: true }));
         } else {
           // If the license is not valid or expired
           setLicenseValid(false);
           setMessage('License has expired or is invalid.');
         }
       } else {
         setLicenseValid(false);
         setMessage('License validation failed.');
       }
     } catch (error) {
       // Catch any error in the API request
       setLicenseValid(false);
       setMessage('License is expired');
     }
   };
 
   validateLicenseKey();
 }, [apiUrl]);  // Ensure apiUrl is included in dependency array
 
 

  // Check license status from localStorage on mount
  useEffect(() => {
    const storedStatus = JSON.parse(localStorage.getItem('license_valid'));
    
    if (storedStatus && storedStatus.valid && storedStatus.expiresAt > Date.now()) {
      setLicenseValid(true);
    } else {
      setLicenseValid(false);
    }
  }, []); 

  

  const validateForm = () => {
    const errors = {};

    if (!email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email address is invalid';
    }

    if (!password) {
      errors.password = 'Password is required';
    } else if (password.length < 6) {
      errors.password = 'Password must be at least 6 characters';
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);

    try {

      const response = await axios.post(`${apiUrl}/login`, { email, password });

      if (response.status === 200) {

        const { access_token, pk_emp_id, first_name, last_name, gender, phone, email, job_title, profile_img } = response.data.user;

        // Store data in local storage
        localStorage.setItem('tokenExpiration', Date.now() + response.data.expiresIn * 1000);
        localStorage.setItem('authToken', access_token);
        localStorage.setItem('emp_id', pk_emp_id);
        localStorage.setItem('first_name', first_name);
        localStorage.setItem('last_name', last_name);
        localStorage.setItem('gender', gender);
        localStorage.setItem('phone', phone);
        localStorage.setItem('email', email);
        localStorage.setItem('job_title', job_title);
        localStorage.setItem('profile_img', profile_img);

        navigate('/dashboard');
      }
    } catch (error) {
      if (error.response) {
        setErrors({ api: error.response.data.message || 'Invalid login credentials provided' });
      } else if (error.request) {
        setErrors({ api: 'No response from server. Please try again later.' });
      } else {
        setErrors({ api: 'An error occurred during login. Please try again.' });
      }
    } finally {
      setLoading(false);
    }
  };

  

  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper account-bg">
          <div className="login-content">
            <form onSubmit={handleSubmit}>
              <div className="login-user-info">
                <div className="login-logo">
                  <img src={`${process.env.PUBLIC_URL}/assets/img/logo.svg`} className="img-fluid" alt="Logo" />
                </div>
                <div className="login-heading">
                  <h4>Sign In</h4>
                  <p>Access the CRMS panel using your email and passcode.</p>
                </div>

                <div className="form-wrap">
                  <label className="col-form-label">Email Address</label>
                  <div className="form-wrap-icon">
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <i className="ti ti-mail"></i>
                    {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                  </div>
                </div>
                <div className="form-wrap">
                  <label className="col-form-label">Password</label>
                  <div className="pass-group">
                    <input
                      type="password"
                      className="pass-input form-control"
                      id="username"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className="ti toggle-password ti-eye-off"></span>
                    {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
                  </div>
                </div>
                <div className="form-wrap form-wrap-checkbox">
                  <div className="custom-control custom-checkbox">
                    <label className="check">
                      <input type="checkbox" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                      <span className="box"></span> Remember Me
                    </label>
                  </div>
                  <div className="text-end">
                    <a href="/" className="forgot-link">Forgot Password?</a>
                  </div>
                </div>
                <div className="form-wrap">
                  {errors.api && <p style={{ color: 'red' }}>{errors.api}</p>}
                  {message && <p style={{ color: licenseValid ? 'green' : 'red' }}>{message}</p>}
                  <button type="submit" className="btn btn-primary" disabled={loading || !licenseValid}>{loading ? 'Logging in...' : 'Login'}</button>
                  {loading && <div className="loader">Loading...</div>}
                </div>
                <div className="login-form">
                  <h6>New on our platform?<a href="/" className="hover-a"> Create an account</a></h6>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
