import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import $ from 'jquery';
import { Modal, Button } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-bs4';

import Header from './Header'

const apiUrl = process.env.REACT_APP_API_BASE_URL;

// All leads List 
const fetchData = async () => {
    const response = await axios.get(`${apiUrl}/leads-list`); 
    return response;
};

// Update lead API request
const updateLead = async (lead) => {
    const response = await axios.put(`${apiUrl}/leads/${lead.pk_lead_id}`, lead); // Replace with your update API endpoint
    return response.data;
  };

  // Validation schema using Yup
const validationSchema = Yup.object().shape({
    lead_first_name: Yup.string().required('Lead name is required'),
    lead_mobile: Yup.string().required('Mobile number is required'),
    lead_email: Yup.string().email('Invalid email format').required('Email is required'),
    lead_sex: Yup.string().required('Gender is required'),
    lead_type: Yup.string().required('Lead type is required'),
    lead_remarks: Yup.string(),
  });

const Leads = () => {
    

    const queryClient = useQueryClient();

    // State for controlling the modal
    const [showModal, setShowModal] = useState(false);
    const [selectedLead, setSelectedLead] = useState(null);

    const [show, setShow] = useState(false);

    // Mutation to update the lead
  const mutation = useMutation({
    mutationFn: updateLead,
    onSuccess: () => {
      queryClient.invalidateQueries(['leadsList']); 
      setShowModal(false); // Close the modal after successful update
    },
  });

    const { data, isLoading, error } = useQuery({
        queryKey: ['leadsList'],  // Pass the query key as an object property
        queryFn: fetchData          // Function that fetches the data
    });


     // Function to open the modal and set the selected lead data
  const handleOpenModal = (lead) => {
    setSelectedLead(lead);
    setShowModal(true);
  };

  
     // Function to open the modal and set the selected lead data
     const handleShow = (lead) => {
        setSelectedLead(lead);
        setShow(true);
      };

      const handleClose = () => setShow(false);   

  // Function to close the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLead(null); // Clear the selected lead
  };


    // Initialize DataTable after the component mounts and the data is fetched
    useEffect(() => {
        if (data) {
            // Initialize DataTable after data has been populated in the table
            $('#categorieslist_data').DataTable({
                destroy: true,  // Destroy any existing DataTable instances before reinitializing
                paging: true,   // Enable pagination
                searching: true, // Enable search functionality
                ordering: true,  // Enable column ordering
            });
        }
    }, [data]);  // Re-run effect when data changes




    return (


        <div className="main-wrapper">

            {/* Header Start  */}
            <Header />
            {/* Header End  */}

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-header">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <h4 className="page-title">Leads<span className="count-title">--</span></h4>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="head-icons">
                                            <a href="contacts.html" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
                                            <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Collapse" id="collapse-header"><i className="ti ti-chevrons-up"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card main-card">
                                <div className="card-body">
                                    <div className="filter-section filter-flex">
                                        {/* <div className="sortby-list">
                                            <ul>

                                                <li>
                                                    <div className="form-wrap icon-form">
                                                        <span className="form-icon"><i className="ti ti-calendar"></i></span>
                                                        <input type="text" className="form-control bookingrange" placeholder />
                                                    </div>
                                                </li>

                                            </ul>
                                        </div> */}

                                        {/* <div className="export-list text-sm-end">
                                            <ul>
                                                <li>
                                                    <div className="export-dropdwon">
                                                        <a href="javascript:void(0);" className="dropdown-toggle" data-bs-toggle="dropdown"><i className="ti ti-package-export"></i>Export</a>
                                                        <div className="dropdown-menu  dropdown-menu-end">
                                                            <ul>
                                                                <li>
                                                                    <a href="javascript:void(0);"><i className="ti ti-file-type-pdf text-danger"></i>Export as PDF</a>
                                                                </li>
                                                                <li>
                                                                    <a href="javascript:void(0);"><i className="ti ti-file-type-xls text-green"></i>Export as Excel </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" className="btn btn-primary add-popup"><i className="ti ti-square-rounded-plus"></i>Add Lead</a>
                                                </li>
                                            </ul>
                                        </div> */}

                                    </div>




                                    <div className="table-responsive custom-table">
                                        <table className="table table-striped table-bordered" id="categorieslist_data" style={{ width: "100%" }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Lead Name</th>
                                                    <th>Lead Mobile</th>
                                                    <th>Lead Email Id</th>
                                                    <th>Gender</th>
                                                    <th>Lead Type</th>
                                                    <th>Lead Remrks</th>
                                                    <th>Leads Source</th>
                                                    <th>Disposition</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {data?.data.data?.map(item => (
                                                    <tr key={item.pk_lead_id}>
                                                        <td> 
                                                        
                                                    <a href="javascript:void(0);" className="btn btn-primary" onClick={() => handleShow(item)}>
                                                    <i className="ti ti-square-rounded-plus"></i>{item.lead_first_name} {item.lead_last_name}
                                                    </a>
                                                        
                                                        </td>
                                                        <td>{item.lead_mobile}</td>
                                                        <td>{item.lead_email}</td>
                                                        <td>{item.lead_sex}</td>
                                                        <td>{item.lead_type}</td>
                                                        <td>{item.lead_remarks}</td>
                                                        <td>{item.fk_source_id}</td>
                                                        <td>{item.fk_disposition_id}</td>
                                                        <td>
                                                            <Button variant="primary" onClick={() => handleOpenModal(item)}>
                                                                Edit
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>


                                         {/* Modal for Viewing Lead Details */}
                                        <Modal show={show} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                            <Modal.Title>Lead Details</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                            {selectedLead && (
                                                <>
                                                <p><strong>Name:</strong> {selectedLead.lead_first_name}</p>
                                                <p><strong>Mobile:</strong> {selectedLead.lead_mobile}</p>
                                                <p><strong>Email:</strong> {selectedLead.lead_email}</p>
                                                <p><strong>Gender:</strong> {selectedLead.lead_sex}</p>
                                                <p><strong>Lead Type:</strong> {selectedLead.lead_type}</p>
                                                <p><strong>Remarks:</strong> {selectedLead.lead_remarks}</p>
                                                </>
                                            )}
                                            </Modal.Body>
                                            <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                            </Modal.Footer>
                                        </Modal>



                                        {/* Modal for editing lead details */}
                                        <Modal show={showModal} onHide={handleCloseModal}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Edit Lead</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {selectedLead && (
                                                    <Formik
                                                        initialValues={{
                                                            lead_first_name: selectedLead.lead_first_name,
                                                            lead_mobile: selectedLead.lead_mobile,
                                                            lead_email: selectedLead.lead_email,
                                                            lead_sex: selectedLead.lead_sex,
                                                            lead_type: selectedLead.lead_type,
                                                            lead_remarks: selectedLead.lead_remarks || '',
                                                        }}
                                                        validationSchema={validationSchema}
                                                        onSubmit={(values) => {
                                                            mutation.mutate({ ...selectedLead, ...values }); // Call the mutation to update the lead
                                                        }}
                                                    >
                                                        {({ errors, touched }) => (
                                                            <Form>
                                                                <div className="form-group">
                                                                    <label>Lead Name</label>
                                                                    <Field name="lead_first_name" className="form-control" />
                                                                    {errors.lead_first_name && touched.lead_first_name && (
                                                                        <div className="text-danger">{errors.lead_first_name}</div>
                                                                    )}
                                                                </div>

                                                                <div className="form-group">
                                                                    <label>Mobile</label>
                                                                    <Field name="lead_mobile" className="form-control" />
                                                                    {errors.lead_mobile && touched.lead_mobile && (
                                                                        <div className="text-danger">{errors.lead_mobile}</div>
                                                                    )}
                                                                </div>

                                                                <div className="form-group">
                                                                    <label>Email</label>
                                                                    <Field name="lead_email" className="form-control" />
                                                                    {errors.lead_email && touched.lead_email && (
                                                                        <div className="text-danger">{errors.lead_email}</div>
                                                                    )}
                                                                </div>

                                                                <div className="form-group">
                                                                    <label>Gender</label>
                                                                    <Field name="lead_sex" as="select" className="form-control">
                                                                        <option value="">Select Gender</option>
                                                                        <option value="Male">Male</option>
                                                                        <option value="Female">Female</option>
                                                                    </Field>
                                                                    {errors.lead_sex && touched.lead_sex && (
                                                                        <div className="text-danger">{errors.lead_sex}</div>
                                                                    )}
                                                                </div>

                                                                <div className="form-group">
                                                                    <label>Lead Type</label>
                                                                    <Field name="lead_type" as="select" className="form-control">
                                                                        <option value="">Select Lead Type</option>
                                                                        <option value="1">Hot</option>
                                                                        <option value="2">Warm</option>
                                                                        <option value="3">Cold</option>
                                                                    </Field>
                                                                  {errors.lead_type && touched.lead_type && (
                                                                        <div className="text-danger">{errors.lead_type}</div>
                                                                    )}
                                                                </div>

                                                                <div className="form-group">
                                                                    <label>Remarks</label>
                                                                    <Field name="lead_remarks" className="form-control" />
                                                                    {errors.lead_remarks && touched.lead_remarks && (
                                                                        <div className="text-danger">{errors.lead_remarks}</div>
                                                                    )}
                                                                </div>

                                                                <Button type="submit" variant="primary" className="mt-3">
                                                                    Update Lead
                                                                </Button>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                )}
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleCloseModal}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>


                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="datatable-length"></div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="datatable-paginate"></div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}

export default Leads;