import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import Papa from 'papaparse'; // For parsing CSV files
import Header from './Header'
import { Formik, useFormik, Form, Field, errors } from 'formik';
import Select from 'react-select';

import * as Yup from 'yup';


import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';

import $ from 'jquery';
import 'datatables.net-bs4';


function EmployeeContactDataCSV() {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    // a local state to store the currently selected file.
    const [csvData, setCsvData]                 = useState([]);
    const [totalCount, setTotalCount]           = useState(0);
    const [data, setData]                       = useState([]);
    const [editContact, setEditContact]         = useState([]);
    const [selectedState, setSelectedState]     = useState('');
    const [countries, setCountries]             = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [states, setStates]                   = useState([]);
    const [cities, setCities]                   = useState([]);
    const [isVisible, setIsVisible]             = useState(false);




    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    setCsvData(results.data);
                }
            });
        }
    };

    const sendDataToApi = () => {
        console.log(csvData);

        axios.post(`${apiUrl}/employee-contact-data-csv`, csvData)
            .then(response => {
                pageLoadData();
                console.log('Data sent successfully:', response.data);
            })
            .catch(error => {
                console.error('Error sending data:', error);
            });
    };

    const sendPublishFinalDataToApi = () => {
        console.log(csvData);

        const userConfirmed = window.confirm("Do you want to publish the completed data?");

        if (userConfirmed) {
                            try {
                                axios.post(`${apiUrl}/publish-employee-contact-data`, csvData)
                                .then(response => {
                                    pageLoadData();
                                    console.log('Data sent successfully:', response.data);
                                })
                                .catch(error => {
                                    console.error('Error sending data:', error);
                                });

            } catch (error) {
                console.error('Error performing action:', error);
            }
        }


        
    };



    useEffect(() => {

        pageLoadData();
    }, []);


    // Fetch countries data
    useEffect(() => {

        axios.post(`${apiUrl}/countrylist`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setCountries(response.data.data);
                    
                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);


    // Fetch states data based on selected country
    useEffect(() => {
        console.log("country id here ====================", selectedCountry)
        if (selectedCountry) {
            axios({
                method: 'POST',
                url: `${apiUrl}/statelist`,
                data: { country: `${selectedCountry}` }
            })
                .then(function (response) {
                    console.log(response)
                    if (Array.isArray(response.data.data)) {
                        setStates(response.data.data);
                        //setStates([]); // Reset cities when country changes
                    } else {
                        console.error("Expected an array but got:", response);
                    }
                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    }, [selectedCountry]);



    // Fetch cities data based on selected state
    useEffect(() => {
        console.log("state id here ====================", selectedState)
        if (selectedState) {
            axios({
                method: 'POST',
                url: `${apiUrl}/citylist`,
                data: { state: `${selectedState}` }
            })
                .then(function (response) {
                    console.log(response)
                    //alert('Successfully signed up!');
                    if (Array.isArray(response.data.data)) {
                        setCities(response.data.data);
                        //setStates([]); // Reset cities when country changes
                    } else {
                        console.error("Expected an array but got:", response);
                    }
                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    }, [selectedState]);


    const pageLoadData = async () => {
        axios.post(`${apiUrl}/employee-contact-tmp-csv-list`)
            .then(response => {
                //alert("111111111");
                if (Array.isArray(response.data.data)) {
                    //alert("asd2222222222222222asd");
                    setData(response.data.data);
                    setTotalCount(response.data.data.length); // Get the total count

                    if (!$.fn.DataTable.isDataTable('#categorieslist_data')) {
                        $('#categorieslist_data').DataTable({
                            data: response.data.data,

                            columns: [
                                { title: "Sr.No", data: "pk_emp_id" },
                                { title: "Category Type", data: "category_type" },
                                { title: "Full Name", data: "first_name" },
                                { title: "DOB", data: "dob" },
                                { title: "Gender", data: "gender" },
                                { title: "Phone", data: "phone" },
                                { title: "Email", data: "email" },
                                { title: "Address", data: "address" },
                                { title: "Job Title", data: "job_title" },
                                {
                                    title: "Edit",
                                    data: "null",
                                    render: function (data, type, row, meta) {
                                       
                                        return `<div class="dropdown table-action">
                                        <a href="#" class="action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right edit-btn" style=""><a class="dropdown-item edit-popup" href="javascript:void(0);" data-id="${row.pk_emp_id}"><i class="ti ti-edit text-blue"></i> Edit</a>
                                        </div></div>`;
                                    }
                                },
                                { title: "Designation", data: "designation" },
                                { title: "Location", data: "location" },
                                { title: "Designation", data: "designation" },
                                { title: "Office Address", data: "office_address" },
                                { title: "Work Phone", data: "work_phone" },
                                { title: "Work Email", data: "work_email" },
                                { title: "Linkedin Profile", data: "linkedin_profile" },
                                { title: "Marital Status", data: "marital_status" },
                                { title: "Spouse Dob", data: "spouse_dob" },
                                { title: "Anniversary", data: "anniversary" },
                                { title: "No Of Kids", data: "no_of_kids" },
                                { title: "Father Name", data: "father_name" },
                                { title: "Mother Name", data: "mother_name" },
                                { title: "Ea Name", data: "ea_name" },
                                { title: "Secretary Name", data: "secretary_name" },
                                { title: "Secretary Phone", data: "secretary_phone" },
                                { title: "Secretary Email", data: "secretary_email" },
                                { title: "Hobbies", data: "hobbies" },
                                { title: "Favorite Sports", data: "favorite_sports" },
                                { title: "Favorite Shows", data: "favorite_shows" },
                                { title: "Favorite Brands", data: "favorite_brands" },
                                { title: "Favorite Travel Destination", data: "favorite_travel_destination" },
                                { title: "Other Interests", data: "other_interests" },
                                { title: "Facebook", data: "facebook" },
                                { title: "Twitter", data: "twitter" },
                                { title: "Instagram", data: "instagram" },
                                { title: "Other Relevant Social Media Platforms", data: "other_relevant_social_media_platforms" },
                                { title: "Pet Type", data: "pet_type" },
                                { title: "Pet Breed", data: "pet_breed" },
                                { title: "Health Parameters Ailments", data: "health_parameters_ailments" },
                                { title: "Health Parameters Allergies", data: "health_parameters_allergies" },
                                { title: "Additional Details Notes", data: "additional_details_notes" },



                            ],
                            order: [[0, 'desc']] // Order by the first column (Full Name) in descending order


                        });
                    } else {
                        const table = $('#categorieslist_data').DataTable();
                        table.clear().rows.add(response.data.data).draw(); // Update table data
                    }


                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the contact list data!", error);
            });

    };

    $("#categorieslist_data_wrapper").off().on("click", '.edit-popup', function () {
        alert('edit id')
        $('.toggle-popup1').addClass('sidebar-popup');
        $('.sidebar-popoverlay').addClass('open');
        const id = $(this).attr('data-id');
        alert(id)
       handleEdit(id);
        return false;
    });


    const handleEdit = async (id) => {
        alert(`Edit button clicked for ID: ${id}`);
        if (id) {
            await axios({
                method: 'POST',
                url: `${apiUrl}/employee-contact-edit-tmp`,
                data: { id: `${id}` }
            })
                .then(function (response) {
                    //alert(response.data.data.first_name);
                    setEditContact(response.data.data);
                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    };


    const formik = useFormik({
        initialValues: {
            category_type                                 : '',
            source_of_contact                             : '',
            profile_img                                   : '',
            first_name                                    : '',
            last_name                                     : '',
            client_dob                                    : '',
            client_gender                                 : '',
            client_phone                                  : '',
            client_address                                : '',
            client_state                                  : '',
            client_city                                   : '',
            client_country                                : '',
            client_email                                  : '',
            client_job_title                              : '',
            department                                    : '',
            location                                      : '',
            client_designation                            : '',
            client_office_address                         : '',
            client_work_phone                             : '',
            client_work_email                             : '',
            work_country                                  : '',
            client_work_state                             : '',
            work_city                                     : '',
            client_linkedin_profile                       : '',
            client_marital_status                         : '',
            client_name_of_spouse                         : '',
            client_spouse_dob                             : '',
            client_spouse_anniversary                     : '',
            client_no_of_kids                             : '',
            client_father_name                            : '',
            client_mother_name                            : '',
            client_ea_name                                : '',
            client_pa_secretary_name                      : '',
            client_pa_secretary_phone                     : '',
            client_pa_secretary_email                     : '',
            client_hobbies                                : '',
            client_favorite_sports                        : '',
            client_favorite_shows                         : '',
            client_favorite_brands                        : '',
            client_favorite_travel_destination            : '',
            client_other_interests                        : '',
            client_facebook                               : '',
            client_twitter                                : '',
            client_instagram                              : '',
            client_other_relevant_social_media_platforms  : '',
            preferred_method_of_contact                   : '',
            client_pet_type                               : '',
            client_breed                                  : '',
            client_ailmments                              : '',
            client_allergies                              : '',
            client_comments                               : '',

        },
       // validationSchema: '',
        enableReinitialize: true,
        //onSubmit: handleOnSubmit,
        onSubmit: (values) => {
            
        }

    });

    useEffect(() => {
       
        if (editContact) {
            setSelectedCountry(editContact.country_id);
            setSelectedState(editContact.state_id);
            
            edit_formik.setValues({
                edit_profile_img: editContact.profile_img || '',
                hdn_edit_profile_img: editContact.profile_img || '',
                edit_pk_emp_id: editContact.pk_emp_id || '',
                edit_pk_emp_id1: editContact.pk_emp_id || '',
                edit_category_type: editContact.category_type || '',
                edit_first_name: editContact.first_name || '',
                edit_last_name: editContact.last_name || '',
                edit_dob: editContact.dob || '',
                edit_gender: editContact.gender || '',
                edit_phone: editContact.phone || '',
                edit_email: editContact.email || '',
                edit_address: editContact.address || '',
                edit_country: editContact.country_id || '',
                edit_state: editContact.state_id || '',
                edit_city: editContact.city_id || '',
                edit_job_title: editContact.job_title || '',
                emp_department: editContact.department || '',
                edit_location: editContact.location || '',
                edit_designation: editContact.designation || '',
                edit_work_address: editContact.work_address || '',
                edit_work_phone: editContact.work_phone || '',
                edit_work_email: editContact.work_email || '',
                edit_work_country: editContact.work_country || '',
                edit_work_state: editContact.work_state || '',
                edit_work_city: editContact.work_city || '',
                edit_linkedin_profile: editContact.linkedin_profile || '',
                edit_marital_status: editContact.marital_status || '',

                edit_name_of_spouse: editContact.name_of_spouse || '',
                edit_spouse_dob: editContact.spouse_dob || '',
                edit_no_of_kids: editContact.no_of_kids || '',
                edit_spouse_anniversary: editContact.anniversary || '',
                edit_father_name: editContact.father_name || '',
                edit_mother_name: editContact.mother_name || '',
                edit_ea_name: editContact.ea_name || '',
                edit_pa_secretary_name: editContact.secretary_name || '',
                edit_pa_secretary_phone: editContact.secretary_phone || '',
                edit_secretary_phone: editContact.secretary_phone || '',
                edit_hobbies: editContact.hobbies || '',
                edit_favorite_sports: editContact.favorite_sports || '',
                edit_favorite_shows: editContact.favorite_shows || '',
                edit_favorite_brands: editContact.favorite_brands || '',
                edit_favorite_travel_destination: editContact.favorite_travel_destination || '',
                edit_other_interests: editContact.other_interests || '',
                edit_facebook: editContact.facebook || '',
                edit_twitter: editContact.twitter || '',
                edit_instagram: editContact.instagram || '',
                edit_other_relevant_social_media_platforms: editContact.other_relevant_social_media_platforms || '',
                edit_preferred_method_of_contact: editContact.preferred_method_of_contact || '',
                edit_pet_type: editContact.pet_type || '',
                edit_breed: editContact.pet_breed || '',
                edit_ailmments: editContact.health_parameters_ailmments || '',
                edit_allergies: editContact.health_parameters_allergies || '',
                edit_comments: editContact.additional_details_notes || '',

            });
        }
    }, [editContact]);


    // Validation schema using Yup
    const edit_schema = Yup.object({
        edit_first_name: Yup.string()
            .required('First name is required')
            .min(2, 'First name must be at least 2 characters'),
        edit_last_name: Yup.string().min(3).required('Last Name is required'),
        edit_dob: Yup.date()
            .nullable()
            .required("Date of birth is required"),

        edit_gender: Yup.string().required('Gender is required'),
        edit_phone: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits')
            .required('Mobile number is required'),
        edit_email: Yup.string().email('Invalid email address').required('Email is required'),
        edit_address: Yup.string().min(3).required('Address is required'),
        edit_country: Yup.string().required('Interests are required'),

    });

    
// Formik setup
    const edit_formik = useFormik({
        initialValues: {
            edit_profile_img                              : '',
            hdn_edit_profile_img                          : '',
            edit_pk_emp_id                                : '',
            edit_pk_emp_id1                               : '',
            edit_category_type                            : '',
            edit_first_name                               : '',
            edit_last_name                                : '',
            edit_dob                                      : '',
            edit_gender                                   : '',
            edit_phone                                    : '',
            edit_email                                    : '',
            edit_address                                  : '',
            edit_country                                  : '',
            edit_state                                    : '',
            edit_city                                     : '',
            edit_job_title                                : '',
            edit_department                               : '',
            edit_location                                 : '',
            edit_designation                              : '',
            edit_office_address                           : '',
            edit_work_phone                               : '',
            edit_work_email                               : '',
            edit_work_country                             : '',
            edit_work_state                               : '',
            edit_work_city                                : '',
            edit_linkedin_profile                         : '',
            edit_marital_status                           : '',
            edit_name_of_spouse                           : '',
            edit_no_of_kids                               : '',
            edit_spouse_anniversary                       : '',
            edit_father_name                              : '',
            edit_mother_name                              : '',
            edit_ea_name                                  : '',
            edit_pa_secretary_name                        : '',
            edit_pa_secretary_phone                       : '',
            edit_pa_secretary_email                       : '',
            edit_hobbies                                  : '',
            edit_favorite_sports                          : '',
            edit_favorite_shows                           : '',
            edit_favorite_brands                          : '',
            edit_favorite_travel_destination              : '',
            edit_other_interests                          : '',
            edit_facebook                                 : '',
            edit_twitter                                  : '',
            edit_instagram                                : '',
            edit_other_relevant_social_media_platforms    : '',
            edit_preferred_method_of_contact              : '',
            edit_pet_type                                 : [],
            edit_breed                                    : [],
            edit_ailmments                                : '',
            edit_allergies                                : '',
            edit_comments                                 : '',
        },
        validationSchema: edit_schema,
        onSubmit: (values) => {
            alert('Form submitted!');

            console.log("===========dddddd=", values.profile_img);
            const formData = new FormData();
            
            formData.append("edit_pk_emp_id", values.edit_pk_emp_id);
            formData.append("edit_pk_emp_id1", values.edit_pk_emp_id1);
            formData.append("edit_category_type", values.edit_category_type);
            formData.append("edit_first_name", values.edit_first_name);
            formData.append("edit_last_name", values.edit_last_name);
            formData.append("edit_dob", values.edit_dob);
            formData.append("edit_gender", values.edit_gender);
            formData.append("edit_phone", values.edit_phone);
            formData.append("edit_email", values.edit_email);
            formData.append("edit_address", values.edit_address);
            formData.append("edit_country", values.edit_country);
            formData.append("edit_state", values.edit_state);
            formData.append("edit_city", values.edit_city);
            formData.append("edit_job_title", values.edit_job_title);
            formData.append("edit_department", values.edit_department);
            formData.append("edit_location", values.edit_location);
            formData.append("edit_designation", values.edit_designation);
            formData.append("edit_office_address", values.edit_office_address);
            formData.append("edit_work_phone", values.edit_work_phone);
            formData.append("edit_work_email", values.edit_work_email);
            formData.append("edit_work_country", values.edit_work_country);
            formData.append("edit_work_state", values.edit_work_state);
            formData.append("edit_work_city", values.edit_work_city);
            formData.append("edit_linkedin_profile", values.edit_linkedin_profile);
            formData.append("edit_marital_status", values.edit_marital_status);
            formData.append("edit_name_of_spouse", values.edit_name_of_spouse);
            formData.append("edit_no_of_kids", values.edit_no_of_kids);

            formData.append("edit_spouse_dob", values.edit_spouse_dob);

            formData.append("edit_spouse_anniversary", values.edit_spouse_anniversary);
            formData.append("edit_father_name", values.edit_father_name);
            formData.append("edit_mother_name", values.edit_mother_name);
            formData.append("edit_ea_name", values.edit_ea_name);
            formData.append("edit_pa_secretary_name", values.edit_pa_secretary_name);
            formData.append("edit_pa_secretary_phone", values.edit_pa_secretary_phone);
            formData.append("edit_pa_secretary_email", values.edit_pa_secretary_email);

            formData.append("edit_hobbies", values.edit_hobbies);
            formData.append("edit_favorite_sports", values.edit_favorite_sports);
            formData.append("edit_favorite_shows", values.edit_favorite_shows);
            formData.append("edit_favorite_brands", values.edit_favorite_brands);
            formData.append("edit_favorite_travel_destination", values.edit_favorite_travel_destination);
            formData.append("edit_other_interests", values.edit_other_interests);
            formData.append("edit_facebook", values.edit_facebook);
            formData.append("edit_twitter", values.edit_twitter);
            formData.append("edit_instagram", values.edit_instagram);
            formData.append("edit_other_relevant_social_media_platforms", values.edit_other_relevant_social_media_platforms);

            formData.append("edit_preferred_method_of_contact", values.edit_preferred_method_of_contact);
            formData.append("edit_pet_type", values.edit_pet_type);
            formData.append("edit_breed", values.edit_breed);

            formData.append("edit_ailmments", values.edit_ailmments);
            formData.append("edit_allergies", values.edit_allergies);
            formData.append("edit_comments", values.edit_comments);

            console.log("===============" + values.edit_pk_emp_id1);
            axios({
                method: 'POST',
                url: `${apiUrl}/edit-tmp-employee-contact`,
                headers: { "Content-Type": "multipart/form-data" },
                //data: values
                data: formData,
            })
                .then(function (res) {
                    console.log(res)
                    alert('Data was successfully updated.');
                    $('.toggle-popup1').removeClass('sidebar-popup');
                    $('.sidebar-popoverlay').removeClass('open');
                    editDataTableRow(values.edit_pk_emp_id, res.data.data)
                    // $("#preview1").show();
                    setIsVisible(!isVisible); // Toggle visibility
                })
                .catch(function (res) {
                    console.log(res,)
                });

        },
    });


    const editDataTableRow = (id, updatedData) => {
        // Get the DataTable instance
        const table = $('#categorieslist_data').DataTable();

        // Find the row that matches the id and update it
        const rowIndex = table.rows().eq(0).filter(function (idx) {
            return table.cell(idx, 0).data() == id; // Assumes first column is the ID
        });

        if (rowIndex.length > 0) {
            table.row(rowIndex).data(updatedData).draw(false); // Update the row data without pagination reset
        }
    };

    

    const deleteTempData = () => {
        const userConfirmed = window.confirm("Are you sure you want to delete this list?");

        if (userConfirmed) {
                            try {
                                axios.post(`${apiUrl}/delete-employee-contact-data`, csvData)
                                .then(response => {
                                    pageLoadData();
                                    console.log('Data sent successfully:', response.data);
                                })
                                .catch(error => {
                                    console.error('Error sending data:', error);
                                });

            } catch (error) {
                console.error('Error performing action:', error);
            }
        }
  };


  const options = [
    { value: 'Cat', label: 'Call' },
    { value: 'Dog', label: 'Dog' },
    { value: 'Others', label: 'Others' },
    { value: 'None', label: 'None' },
];


const setInputValue = useCallback(
    (key, value) =>
        formik.setValues({
            ...formik.values,
            [key]: value,
        }),
    [formik]
);



    return (


        <div className="main-wrapper">

            {/* Header Start  */}
            <Header />
            {/* Header End  */}

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                           
                            <div className="page-header">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <h4 className="page-title">Employee Contacts Data Import<span className="count-title">{totalCount}</span></h4>
                                    </div>
                                    <div className="col-4">
                                        <h4 className="page-title">Download Sample File <a href="./assets/downloads/employee-contact-data-csv.csv"> <i class="fa-solid fa-download"></i></a></h4>
                                    </div>

                                    <div className="col-4 text-end">
                                        <div className="head-icons">
                                            <a href="contacts.html" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
                                            <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Collapse" id="collapse-header"><i className="ti ti-chevrons-up"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="card main-card">
                                <div className="card-body">

                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <label class="col-form-label">Attachment <span class="text-danger">*</span></label>
                                            <input type="file" accept=".csv" onChange={handleFileUpload} />
                                            <button class="btn btn-primary" onClick={sendDataToApi}>Send Data</button>

                                            {/* <div class="mb-3">
                                            <label class="col-form-label">Attachment <span class="text-danger">*</span></label>
                                            <div class="drag-attach">
                                                <input type="file" accept=".csv" onChange={handleFileUpload} />
                                                <div class="img-upload">
                                                    <i class="ti ti-file-broken"></i>Upload File
                                                </div>
                                                <button type="button" onClick={sendDataToApi} class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#create_success">Create</button>
                                            </div>
                                        </div> */}
                                        </div>

                                        <div className="col-6">
                                            <button class="btn btn-info" onClick={sendPublishFinalDataToApi}>Publish Final Data After Review</button>
                                        </div>
                                    </div>

                                    
                                    <div className="table-responsive custom-table pipeline-listing mt-5">
                                    <button class="btn btn-info" onClick={deleteTempData}>Delete Data</button>
                                        <table className="table table-striped table-bordered" id="categorieslist_data" style={{ width: "100%" }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className="no-sort">
                                                        <label className="checkboxs">
                                                        <input type="checkbox" id="select-all" /><span className="checkmarks"></span></label>
                                                    </th>
                                                    <th className="no-sort"></th>
                                                    <th>Name</th>
                                                    <th>Phone</th>
                                                    <th>Email</th>
                                                    <th>Tags</th>
                                                    <th>Location</th>
                                                    <th>Rating</th>
                                                    <th>Status</th>
                                                    <th className="text-end">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="datatable-length"></div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="datatable-paginate"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






            <div className="toggle-popup1">
                <div className="sidebar-layout">
                    <div className="sidebar-header">
                        <h4>Edit Contact</h4>
                        <a href="#" className="sidebar-close1 toggle-btn"><i className="ti ti-x"></i></a>
                    </div>
                    <div className="toggle-body">
                        <div className="pro-create">


                            <form onSubmit={edit_formik.handleSubmit}>
                                <input type="hidden1" name="edit_pk_emp_id" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_pk_emp_id1} />

                                {/* <input type="text" className="form-control" name="edit_pk_emp_id" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_pk_emp_id1} /> */}

                                <div className="accordion-lists" id="list-accords">


                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap" data-bs-toggle="collapse" data-bs-target="#contacttype"><span><i className="ti ti-user-plus"></i></span>Contact Type (Tags/Categories)</a>
                                        <div className="accordion-collapse collapse show" id="contacttype" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">

                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Category Type</label>
                                                            <select className="form-control" as="select" name="edit_category_type" onChange={edit_formik.handleChange} >
                                                                <option value="">Select Client Tags/Categories</option>
                                                                <option value="VIP" selected={'VIP' === editContact.category_type}>VIP</option>
                                                                <option value="High Priority" selected={'High Priority' === editContact.category_type}>High Priority</option>
                                                                <option value="Potential Upsell" selected={'Potential Upsell' === editContact.category_type}>Potential Upsell</option>
                                                                <option value="Agent" selected={'Agent' === editContact.category_type}>Agent</option>
                                                                <option value="Supplier" selected={'Supplier' === editContact.category_type}>Supplier</option>
                                                                <option value="Intern" selected={'Intern' === editContact.category_type}>Intern</option>
                                                                <option value="Competitor" selected={'Competitor' === editContact.category_type}>Competitor</option>
                                                                <option value="Others" selected={'Others' === editContact.category_type}>Others</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#editBasicInformation"><span><i className="ti ti-user-plus"></i></span>Basic Details</a>

                                        <div className="accordion-collapse collapse" id="editBasicInformation" data-bs-parent="#list-accords">
                                            <div className="content-collapse">
                                                <div className="row">

                                                    

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">First Name <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_first_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_first_name} />
                                                            <small className="error">{edit_formik.errors.edit_first_name}</small>

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Last Name <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_last_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_last_name} />
                                                            <small className="error">{edit_formik.errors.edit_last_name}</small>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Date of Birth <span className="text-danger">*</span></label>
                                                            {/* <input type="date" className="form-control" name="edit_dob" value={editContact.dob} onChange={(e) => setInputValue("edit_dob", e.target.value)} /> */}
                                                            <input type="date" className="form-control" name="edit_dob" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_dob} />
                                                            <small className="error">{edit_formik.errors.edit_dob}</small>

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Gender <span className="text-danger">*</span></label>
                                                            <select className="form-control" name="edit_gender" as="select" onChange={edit_formik.handleChange}   >
                                                                <option value="">Select Gender</option>
                                                                <option value="Male" selected={'Male' === editContact.gender}>Male</option>
                                                                <option value="Female" selected={'Female' === editContact.gender}>Female</option>
                                                            </select>
                                                            <small className="error">{edit_formik.errors.edit_gender}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Phone <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_phone" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_phone} />
                                                            <small className="error">{edit_formik.errors.edit_phone}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Email <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_email" value={edit_formik.values.edit_email} onChange={edit_formik.handleChange} />
                                                            <small className="error">{edit_formik.errors.edit_email}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Address <span className="text-danger">*</span></label>
                                                            <textarea class="form-control" id="client_address" rows="3" name="edit_address" value={edit_formik.values.edit_address} onChange={edit_formik.handleChange} ></textarea>
                                                            <small className="error">{edit_formik.errors.edit_address}</small>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Country <span className="text-danger">*</span></label>
                                                            <select className="form-control" name="edit_country" as="select" value={edit_formik.values.edit_country} onBlur={edit_formik.handleBlur} onChange={edit_formik.handleChange} >
                                                                <option value="">Select Country</option>
                                                                {countries && countries.map((country, cases) => (
                                                                    <option key={country.pk_country_id} value={country.pk_country_id}>{country.country_name}</option>
                                                                ))}
                                                            </select>
                                                            <small className="error">{edit_formik.errors.edit_country}</small>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">State <span className="text-danger">*</span></label>
                                                            <select className="form-control" name="edit_state" as="select" value={editContact.state_id} onChange={e => setSelectedState(e.target.value)}>
                                                                <option value="">Select State</option>
                                                                {states.map((state) => (
                                                                    <option key={state.pk_state_id} value={state.pk_state_id}>{state.state}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">City <span className="text-danger">*</span></label>
                                                            <select disabled={!selectedState} className="form-control" name="edit_city" as="select" value={editContact.city_id} onChange={(e) => setInputValue('edit_city', e.target.value)}>
                                                                <option value="">Select City</option>
                                                                {cities.map((city) => (
                                                                    <option key={city.pk_city_id} value={city.pk_city_id}>{city.city_name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit-address"><span><i className="ti ti-map-pin-cog"></i></span>Professional Details</a>
                                        <div className="accordion-collapse collapse" id="edit-address" data-bs-parent="#list-accords">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Job Title <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_job_title" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_job_title} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Department<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="emp_department" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_department} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Location<span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_location" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_location} />
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Designation <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_designation" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_designation} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Office Address <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_office_address" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_office_address} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Country <span className="text-danger">*</span></label>

                                                            <select className="form-control" name="edit_work_country" as="select" value={edit_formik.work_country} onChange={formik.handleChange}>
                                                                <option value="">Select Country</option>
                                                                {countries && countries.map((country, cases) => (
                                                                    <option key={country.pk_country_id} value={country.pk_country_id}>{country.country_name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">State <span className="text-danger">*</span></label>
                                                            <select className="form-control" name="edit_work_state" as="select" value={edit_formik.work_state} onChange={e => setSelectedState(e.target.value)} disabled={!selectedCountry}>
                                                                <option value="">Select State</option>
                                                                {states.map((state) => (
                                                                    <option key={state.pk_state_id} value={state.pk_state_id}>{state.state}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Work City <span className="text-danger">*</span></label>
                                                            <select disabled={!selectedState} className="form-control" name="edit_work_city" as="select" value={edit_formik.work_city} onChange={(e) => setInputValue('edit_work_city', e.target.value)}>
                                                                <option value="">Select City</option>
                                                                {cities.map((city) => (
                                                                    <option key={city.pk_city_id} value={city.pk_city_id}>{city.city_name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Work Phone <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_work_phone" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_work_phone} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Work Email <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_work_email" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_work_email} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">LinkedIn Profile <span className="text-danger">*</span></label>
                                                            <input type="text" className="form-control" name="edit_linkedin_profile" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_linkedin_profile} />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit_account_mapping"><span><i className="ti ti-map-pin-cog"></i></span>Reporting</a>
                                        <div className="accordion-collapse collapse" id="edit_account_mapping" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Reporting Manager</label>
                                                            <select class="multiple-img" multiple="multiple">
                                                                <option data-image="assets/img/profiles/avatar-18.jpg" selected>Jerald</option>
                                                                <option data-image="assets/img/profiles/avatar-20.jpg">Sharon Roy</option>
                                                                <option data-image="assets/img/profiles/avatar-21.jpg">Vaughan</option>
                                                                <option data-image="assets/img/profiles/avatar-23.jpg">Jessica</option>
                                                                <option data-image="assets/img/profiles/avatar-16.jpg">Carol Thomas</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit_personal_details"><span><i className="ti ti-social"></i></span>Personal Details</a>
                                        <div className="accordion-collapse collapse" id="edit_personal_details" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Marital Status</label>

                                                            <select className="form-control" as="select" name="edit_marital_status" onChange={edit_formik.handleChange} >
                                                                <option value="">Select Marital Status</option>
                                                                <option value="Married" selected={'Married' === editContact.marital_status}>Married</option>
                                                                <option value="Single" selected={'Single' === editContact.marital_status}>Single</option>
                                                                <option value="Others" selected={'Others' === editContact.marital_status}>Others</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Name of Spouse</label>
                                                            <input type="text" className="form-control" name="edit_name_of_spouse" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_name_of_spouse} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Spouse Date of Birth</label>
                                                            {/* <input type="text" class="form-control datetimepicker" name="edit_spouse_dob" placeholder="DD/MM/YY" value={editContact.spouse_dob} onChange={(e) => setInputValue("edit_spouse_dob", e.target.value)} /> */}

                                                            <input type="date" className="form-control" placeholder="DD/MM/YY" name="edit_spouse_dob" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_spouse_dob} />



                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Anniversary</label>
                                                            {/* <input type="text" class="form-control datetimepicker" name="edit_spouse_anniversary" value={editContact.spouse_anniversary} onChange={(e) => setInputValue("edit_spouse_anniversary", e.target.value)} /> */}

                                                            <input type="date" className="form-control" placeholder="DD/MM/YY" name="edit_spouse_anniversary" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_spouse_anniversary} />

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">No. of Kids</label>
                                                            <select className="form-control" as="select" name="edit_no_of_kids" onChange={edit_formik.handleChange} >
                                                                <option value="">Select No. of kids</option>
                                                                <option value="1" selected={'1' === editContact.no_of_kids}>1</option>
                                                                <option value="2" selected={'2' === editContact.no_of_kids}>2</option>
                                                                <option value="3" selected={'3' === editContact.no_of_kids}>3</option>
                                                                <option value="4" selected={'4' === editContact.no_of_kids}>4</option>
                                                                <option value="More than 4" selected={'More than 4' === editContact.no_of_kids}>More than 4</option>
                                                            </select>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Father Name</label>
                                                            <input type="text" className="form-control" name="edit_father_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_father_name} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Mother Name</label>
                                                            <input type="text" className="form-control" name="edit_mother_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_mother_name} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">EA Name</label>
                                                            <input type="text" className="form-control" name="edit_ea_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_ea_name} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">PA/Secretary Name</label>
                                                            <input type="text" className="form-control" name="edit_pa_secretary_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_pa_secretary_name} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">PA/Secretary Phone</label>
                                                            <input type="text" className="form-control" name="edit_pa_secretary_phone" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_pa_secretary_phone} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">PA/Secretary Email</label>
                                                            <input type="text" className="form-control" name="edit_pa_secretary_email" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_pa_secretary_email} />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit_personal_interests"><span><i className="ti ti-social"></i></span>Personal Interests</a>
                                        <div className="accordion-collapse collapse" id="edit_personal_interests" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Hobbies</label>
                                                            <input type="text" className="form-control" name="edit_hobbies" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_hobbies} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Favorite Sports</label>
                                                            <input type="text" className="form-control" name="edit_favorite_sports" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_favorite_sports} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Favorite Books/Movies/TV Shows</label>
                                                            <input type="text" className="form-control" name="edit_favorite_shows" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_favorite_shows} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Favorite Brands</label>
                                                            <input type="text" className="form-control" name="edit_favorite_brands" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_favorite_brands} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Favorite Travel Destination</label>
                                                            <input type="text" className="form-control" name="edit_favorite_travel_destination" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_favorite_travel_destination} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Other Interests</label>
                                                            <input type="text" className="form-control" name="edit_other_interests" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_other_interests} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit_social_media_profiles"><span><i className="ti ti-accessible"></i></span>Social Media Profiles</a>
                                        <div className="accordion-collapse collapse" id="edit_social_media_profiles" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Facebook</label>
                                                            <input type="text" className="form-control" name="edit_facebook" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_facebook} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Twitter</label>
                                                            <input type="text" className="form-control" name="edit_twitter" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_twitter} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Instagram</label>
                                                            <input type="text" className="form-control" name="edit_instagram" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_instagram} />
                                                        </div>
                                                    </div>



                                                    <div className="col-md-6">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Other relevant social media platforms</label>
                                                            <input type="text" className="form-control" name="edit_other_relevant_social_media_platforms" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_other_relevant_social_media_platforms} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                     <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit_pets"><span><i className="ti ti-accessible"></i></span>Pets</a>
                                        <div className="accordion-collapse collapse" id="edit_pets" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label className="col-form-label">Type</label>
                                                       <Select
                                                            id="edit_pet_type"
                                                            name="edit_pet_type"
                                                            options={options}
                                                            isMulti
                                                            value={options.filter(option => edit_formik.values.edit_pet_type.includes(option.value))}
                                                            onChange={(selectedOptions) => {
                                                                edit_formik.setFieldValue(
                                                                    'edit_pet_type',
                                                                    selectedOptions ? selectedOptions.map(option => option.value) : []
                                                                );
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Breed<span className="text-danger">*</span></label>
                                                            {/* <select class="multiple-img" multiple="multiple" name="edit_breed">
                                                <option data-image="assets/img/profiles/cat.jpg" value="Cat">Cat</option>
                                                <option data-image="assets/img/profiles/dog.jpg" value="Dog">Dog</option>
                                                <option data-image="assets/img/profiles/avatar-19.jpg" value="Others">Others</option>
                                                <option data-image="assets/img/profiles/avatar-19.jpg" value="None">None</option>
                                             </select> */}

                                                            <Select
                                                                id="edit_breed"
                                                                name="edit_breed"
                                                                options={options}
                                                                isMulti
                                                                value={options.filter(option => edit_formik.values.edit_breed.includes(option.value))}
                                                                onChange={(selectedOptions) => {
                                                                    edit_formik.setFieldValue(
                                                                        'edit_breed',
                                                                        selectedOptions ? selectedOptions.map(option => option.value) : []
                                                                    );
                                                                }}
                                                            />

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit_health_parameters"><span><i className="ti ti-accessible"></i></span>Health Parameters</a>
                                        <div className="accordion-collapse collapse" id="edit_health_parameters" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Ailments</label>
                                                            <input type="text" className="form-control" name="edit_ailmments" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_ailmments} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Allergies</label>
                                                            <input type="text" className="form-control" name="edit_allergies" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_allergies} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="user-accordion-item">
                                        <a href="#" className="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#edit_additionaldetails"><span><i className="ti ti-accessible"></i></span>Additional Details</a>
                                        <div className="accordion-collapse collapse" id="edit_additionaldetails" data-bs-parent="#list-accord">
                                            <div className="content-collapse">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-wrap">
                                                            <label className="col-form-label">Notes/Comments:</label>
                                                            <input type="text" className="form-control" name="edit_comments" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_comments} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="submit-button text-end">
                                    <a href="#" className="btn btn-light sidebar-close1">Cancel</a>
                                    <button type="submit" className="btn btn-primary">
                                        Save Changes
                                    </button>

                                    {/* <a href="#" className="btn btn-primary">Save Changes</a> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>











        </div>

    )
}

export default EmployeeContactDataCSV;