import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import $ from 'jquery';
import { Modal, Button } from 'react-bootstrap';
import { Formik, useFormik, Form, Field, errors } from 'formik';
import * as Yup from 'yup';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-bs4';

import Header from './Header'



const useQueryUTM = () => {
    return new URLSearchParams(useLocation().search);
};



// Fetch existing leads
const fetchCompanyData = async () => {
    const response = await axios.get('/company-list');
    console.log("Company list", response.data);
    return response;
};

const Permissions = () => {


    const query = useQueryUTM();
    const role_id = query.get('permissionId');

    const [inputValue, setInputValue] = useState(''); // Define state for input


    const [show, setShow] = useState(false);
    const [permission, setPermission] = useState([]);


    const [checkboxes, setCheckboxes] = useState({
        edit_create: false,
        checkbox2: false,
        checkbox3: false,
    });

    const [updatedData, setUpdatedData] = useState(null); // Store updated data


    // Fetch cities data based on selected state
    useEffect(() => {
        console.log("Role id here ====================", role_id)
        if (role_id) {
            axios({
                method: 'POST',
                url: '/role-permission-data',
                data: { role_id: `${role_id}` }
            })
                .then(function (response) {
                    console.log(response)
                    //alert('Successfully signed up!');
                    if (Array.isArray(response.data.data)) {
                        setPermission(response.data.data);
                        //setStates([]); // Reset cities when country changes
                    } else {
                        console.error("Expected an array but got:", response);
                    }
                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    }, [role_id]);


    // Function to update a company
    const updateCompany = (values) => {

        const res = axios.put(`/update-company/${values.edit_company_id}`, values); // Update company endpoint
        //refetch()
        return res;
    }


    const queryClient = useQueryClient(); // For cache management

    // Fetch existing leads using useQuery
    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['companyList'],
        queryFn: fetchCompanyData,
    });




    // Mutation to update the lead
    const updateMutation = useMutation({
        mutationFn: updateCompany,
        onSuccess: () => {
            queryClient.invalidateQueries(['companyList']);
        },
    });


    // Mutation for adding a new lead
    const mutation = useMutation({
        mutationFn: 'addCompany',
        onSuccess: (newCompany) => {
            queryClient.setQueryData(['newCompanyList'], (oldData) => ({
                ...oldData,
                data: [...oldData.data, newCompany], // Append new lead to the existing leads
            }));
        },
    });




 



    //#######################  Update from ####################################//

    useEffect(() => {
      
        if (permission && Array.isArray(permission) && permission[0] !== undefined && permission[0].modulePermissions.submodules[0] !== undefined) {
            
            if (permission && Array.isArray(permission) && permission[0] !== undefined && permission[0].modulePermissions.submodules[0] !== undefined) {
    
                
                 
                const actionsArray = permission[0].modulePermissions.submodules[0].actions;
                console.log("Hi Hello===============", permission[0]);
                
                edit_formik.setValues({
                        edit_role_id: permission[0].role_id || '',
                        edit_role_name: permission[0].role_name || '',
                        edit_modulePermissions: permission[0].modulePermissions.edit_module_name || '',
                        submodules: permission[0].modulePermissions.submodules.map(sub => ({
                        module_permission_id: sub.module_permission_id || '',
                        name: sub.name || '',
                        selectedActions: sub.actions || [], // Initialize as an array
                    })),

                });
                
            }else{

                alert('2222');
            }
        }

    }, [permission]); // Add edit_formik to dependency array



    const edit_schema = Yup.object().shape({
        edit_role_name: Yup.string().min(3).required('Role name is required'),
    });

    
    const edit_formik = useFormik({
        initialValues: {
            edit_role_name   : '',
            edit_submodules  : '',
            submodules       : [],
            edit_checkbox    : [],
            edit_create      : [],
            edit_view        : [],
            edit_update      : [],
            edit_delete      : [],
            action           : [],

        },
        validationSchema: edit_schema,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log("=========Edit Role Permission====", values)
           const res = axios.put(`/update-permissions/${values.edit_role_id}`, values); // Update company endpoint
            $('#rolewise_module_permission_id').show();
            $('.sidebar-popoverlay').addClass('open');

            // updateCompany(values)
            //updateMutation.mutate({ ...permission, ...values }); // Call the mutation to update the lead

        },
      
    });



    const setInputValueEdit = useCallback(
        (key, value) =>
            edit_formik.setValues({
                ...edit_formik.values,
                [key]: value,
            }),
        [edit_formik]
    );



    // Function to update a company
    const updateRoleWiseModulePermissionsStatus = (values) => {
        console.log("===========update company state===", values);
        const res = axios.put(`/update-company-status/${values.companyId}`, values); // Update company endpoint
       
        //refetch()
        return res;
    }


    const statusMutation = useMutation({
        mutationFn: updateRoleWiseModulePermissionsStatus,
        onSuccess: () => {
          queryClient.invalidateQueries(['companyList']); 
          //alert('Successfully updated data');
        },
      });





    return (


        <div className="main-wrapper">

            {/* Header Start  */}
            <Header />
            {/* Header End  */}

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-header">
                                
                                <div className="row align-items-center">
                                    <div className="col-8"> 
                                        <h4 className="page-title">Permission<span className="count-title">-</span></h4>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="head-icons">
                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
                                            <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Collapse" id="collapse-header"><i className="ti ti-chevrons-up"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div class="contact-head">
                                    <div class="row align-items-center">
                                        <div class="col-sm-6">
                                            <ul class="contact-breadcrumb">
                                                <li><a href="/roles-permissions"><i class="ti ti-arrow-narrow-left"></i>Roles Permissions</a></li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>

                            </div>
                            <div className="card main-card">

                                <div className="card-body">
                                   
                                        
                                            <div className="pro-create">

                                         <form onSubmit={edit_formik.handleSubmit} >
                                                    <div className="row">

                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <label className="col-form-label">Role Name<span className="text-danger">*</span></label>
                                                                {/* <input className="form-control" name="role_name" id="role_name"
                                                                    value={edit_formik.values.edit_role_name}
                                                                    onChange={edit_formik.handleChange}
                                                                    onBlur={edit_formik.handleBlur}
                                                                /> */}

                                                                <input className="form-control" name="edit_role_name" value={edit_formik.values.edit_role_name} onChange={(e) => setInputValueEdit("edit_role_name", e.target.value)} />
                                                                <small className="error">{edit_formik.errors.role_name}</small>
                                                              </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <label className="col-form-label">Module Name  <span className="text-danger">*</span></label>
                                                                </div>
                                                                <input className="form-control" name="module_name" value={edit_formik.values.edit_modulePermissions} onChange={(e) => setInputValueEdit("edit_modulePermissions", e.target.value)}
                                                                />
                                                                <small className="error">{edit_formik.errors.edit_modulePermissions}</small>
                                                            </div>
                                                        </div>
                                                            
                                                        <div className="col-lg-12 col-md-12">
                                                           {edit_formik.values.submodules.map((submodule, index) => (
                                                                <div key={index} className="mt-3">
                                                                    {/* Submodule Name */}
                                                                    <label htmlFor={`submodules.${index}.name`}>Submodule Name</label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        name={`submodules[${index}].name`}
                                                                        id={`submodules.${index}.name`}
                                                                        value={submodule.name}
                                                                        onChange={edit_formik.handleChange}
                                                                        onBlur={edit_formik.handleBlur}
                                                                    />
                                                                    {edit_formik.touched.submodules &&
                                                                        edit_formik.touched.submodules[index] &&
                                                                        edit_formik.errors.submodules &&
                                                                        edit_formik.errors.submodules[index] &&
                                                                        edit_formik.errors.submodules[index].name && (
                                                                            <div className="invalid-feedback">
                                                                                {edit_formik.errors.submodules[index].name}
                                                                            </div>
                                                                        )}

                                                                    {/* Action Checkboxes */}
                                                                    <div className="mb-3">
                                                                        <div className="d-flex flex-wrap mt-3">
                                                                            {['Create', 'Update', 'Delete', 'View'].map(action => (
                                                                                <div className="me-2" key={action}>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name={`submodules[${index}].selectedActions`}
                                                                                        value={action}
                                                                                        checked={submodule.selectedActions.includes(action)}
                                                                                        onChange={(e) => {
                                                                                            const { checked, value } = e.target;
                                                                                            if (checked) {
                                                                                                // Add action to selectedActions
                                                                                                edit_formik.setFieldValue(`submodules[${index}].selectedActions`, [
                                                                                                    ...submodule.selectedActions,
                                                                                                    value,
                                                                                                ]);
                                                                                            } else {
                                                                                                // Remove action from selectedActions
                                                                                                edit_formik.setFieldValue(`submodules[${index}].selectedActions`, submodule.selectedActions.filter(a => a !== value));
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <label className="ms-1">{action}</label>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}

                                                      </div>

                                                  </div>
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        {/* <button type="button" data-bs-dismiss="offcanvas" className="btn btn-light me-2">Cancel</button> */}
                                                        <button type="submit" className="btn btn-primary">Update </button>
                                                    </div>
                                                </form>
                                            </div>
                                        

                                     

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="modal custom-modal fade" id="rolewise_module_permission_id" role="dialog" style={{ opacity: 1 }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 m-0 justify-content-end">
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ti ti-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="success-message text-center">
                                <div className="success-popup-icon bg-light-blue">
                                    <i className="ti ti-user-plus"></i>
                                </div>
                                <h3>Role wise module permission updated successfully!!!</h3>
                                <p>View the details of rolewise module permission</p>
                                <div className="col-lg-12 text-center modal-btn">
                                    <a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                                    {/* <a href="/contacts" re className="btn btn-primary">View Details</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>

    )
}

export default Permissions;