import React, { createContext, useState, useEffect } from 'react';

// Create a ProfileContext
export const ProfileContext = createContext();

// ProfileProvider component to manage global state
export const ProfileProvider = ({ children }) => {
  const [profileImage, setProfileImage] = useState(localStorage.getItem('profileImage') || '');

  // Update localStorage whenever profileImage state changes
  useEffect(() => {
    localStorage.setItem('profileImage', profileImage);
  }, [profileImage]);

  // Function to update the profile image globally
  const updateProfileImage = (newImage) => {
    setProfileImage(newImage);
  };

  return (
    <ProfileContext.Provider value={{ profileImage, updateProfileImage }}>
      {children}
    </ProfileContext.Provider>
  );
};
