import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import $ from 'jquery';
import { Modal, Button } from 'react-bootstrap';
import { Formik, useFormik, Form, Field, errors } from 'formik';
import * as Yup from 'yup';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-bs4';

import Header from './Header'


const apiUrl = process.env.REACT_APP_API_BASE_URL;

// Fetch existing leads
const fetchCompanyData = async () => {
    const response = await axios.get(`${apiUrl}/company-list`);
    console.log("Company list", response.data);
    return response;
};

const Company = () => {

    const [inputValue, setInputValue] = useState(''); // Define state for input

    // Add new lead API call
    const addCompany = async (newCompany) => {
        const response = await axios.post(`${apiUrl}/add-company`, newCompany);
        return response.data;
    };


    // Function to update a company
    const updateCompany = (values) => {

        const res = axios.put(`${apiUrl}/update-company/${values.edit_company_id}`, values); // Update company endpoint
        //refetch()
        return res;
    }


    const queryClient = useQueryClient(); // For cache management

    // Fetch existing leads using useQuery
    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['companyList'],
        queryFn: fetchCompanyData,
    });
    

     

     // Mutation to update the lead
  const updateMutation = useMutation({
    mutationFn: updateCompany,
    onSuccess: () => {
      queryClient.invalidateQueries(['companyList']); 
    },
  });
    

    // Mutation for adding a new lead
    const mutation = useMutation({
        mutationFn: addCompany,
        onSuccess: (newCompany) => {
            // Update the leads list without refreshing by invalidating the query or updating cache manually
            queryClient.setQueryData(['newCompanyList'], (oldData) => ({
                ...oldData,
                data: [...oldData.data, newCompany], // Append new lead to the existing leads
            }));
        },
    });

    


 //   const { data: companyList } = useQuery({
    //     queryKey: ['companyList'],  // Pass the query key as an object property
    //     queryFn: fetchCompanyData          // Function that fetches the data
    // });

    // State for modal and form visibility
    const [show, setShow] = useState(false);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const [selectedCompany, setSelectedCompany] = useState(null);



    // Fetch countries data
    useEffect(() => {

        axios.post(`${apiUrl}/countrylist`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setCountries(response.data.data);

                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);


    // Fetch states data based on selected country
    useEffect(() => {
        console.log("country id here ====================", selectedCountry)
        if (selectedCountry) {
            axios({
                method: 'POST',
                url: `${apiUrl}/statelist`,
                data: { country: `${selectedCountry}` }
            })
                .then(function (response) {
                    console.log(response)
                    if (Array.isArray(response.data.data)) {
                        setStates(response.data.data);
                        //setStates([]); // Reset cities when country changes
                    } else {
                        console.error("Expected an array but got:", response);
                    }
                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    }, [selectedCountry]);



    // Fetch cities data based on selected state
    useEffect(() => {
        console.log("state id here ====================", selectedState)
        if (selectedState) {
            axios({
                method: 'POST',
                url: `${apiUrl}/citylist`,
                data: { state: `${selectedState}` }
            })
                .then(function (response) {
                    console.log(response)
                    //alert('Successfully signed up!');
                    if (Array.isArray(response.data.data)) {
                        setCities(response.data.data);
                        //setStates([]); // Reset cities when country changes
                    } else {
                        console.error("Expected an array but got:", response);
                    }
                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    }, [selectedState]);



    const schema = Yup.object().shape({
        company_name: Yup.string().min(3).required('Company name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        phone: Yup.string().required('Phone number is required'),
        website: Yup.string().required('website is required'),
    });

    // Formik setup
    const formik = useFormik({
        initialValues: {
            company_name       : '',
            email              : '',
            phone              : '',
            website            : '',
            deals_id           : '',
            industry           : '',
            language           : '',
            address            : '',
            city               : '',
            state              : '',
            country            : '',
            pincode            : '',
            facebook           : '',
            linkedin           : '',
            twitter            : '',
            whatsapp           : '',
            instagram          : '',
            zipcode            : '',


        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: (values) => {
            mutation.mutate(values); // Pass form values to the mutation function
            alert('Successfully added data');

            $('.toggle-popup1').removeClass('sidebar-popup');
            $('.sidebar-popoverlay').removeClass('open');

        },
    });


    //#######################  Update from ####################################//





    useEffect(() => {
        console.log("Hi===============", selectedCompany)
        if (selectedCompany) {
            edit_formik.setValues({
                edit_company_id: selectedCompany.pk_company_id || '',
                edit_company_name: selectedCompany.company_name || '',
                edit_email: selectedCompany.email || '',
                edit_phone: selectedCompany.phone || '',
                edit_website: selectedCompany.website || '',
                edit_industry: selectedCompany.industry || '',
                edit_language: selectedCompany.language || '',
                edit_address: selectedCompany.address || '',
                edit_country: selectedCompany.country_id || '',
                edit_state: selectedCompany.fk_state_id || '',
                edit_city: selectedCompany.fk_city_id || '',
                edit_zipcode: selectedCompany.zipcode || '',
                edit_facebook: selectedCompany.facebook || '',
                edit_linkedin: selectedCompany.linkedin || '',
                edit_twitter: selectedCompany.twitter || '',
                edit_whatsapp: selectedCompany.whatsapp || '',
                edit_instagram: selectedCompany.instagram || '',

            });
        }
    }, [selectedCompany]); // Add edit_formik to dependency array



    const edit_schema = Yup.object().shape({
        edit_company_name: Yup.string().min(3).required('Company name is required'),
        edit_email: Yup.string().email('Invalid email address').required('Email is required'),
        edit_phone: Yup.string().required('Phone number is required'),
        edit_website: Yup.string().required('website is required'),
    });

    const edit_formik = useFormik({
        initialValues: {
            edit_company_id          : '',
            edit_company_name        : '',
            edit_phone               : '',
            edit_email               : '',
            edit_website             : '',
            edit_industry            : '',
            edit_language            : '',
            edit_address             : '',
            edit_country             : '',
            edit_state               : '',
            edit_city                : '',
            edit_zipcode             : '',
            edit_facebook            : '',
            edit_linkedin            : '',
            edit_twitter             : '',
            edit_whatsapp            : '',
            edit_instagram           : '',

        },
        validationSchema: edit_schema,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log("=========Edit Company Id====", values.edit_company_id)
           // updateCompany(values)

           updateMutation.mutate({ ...selectedCompany, ...values }); // Call the mutation to update the lead
           
            $('.toggle-popup1').removeClass('sidebar-popup');
            $('.sidebar-popoverlay').removeClass('open');

        },
        // Add validationSchema or other options if needed
    });  


    // Function to open the modal and set the selected lead data
    const handleOpenModal = (companyEditData) => {
        console.log("============ com edit data===", companyEditData)
        setSelectedCompany(companyEditData);
        setSelectedState(companyEditData.fk_state_id);
        setSelectedCountry(companyEditData.country_id)
        //setShowModal(true);
        $('.toggle-popup1').addClass('sidebar-popup');
        $('.sidebar-popoverlay').addClass('open');
        //const id = $(this).attr('data-id');

    };


// Initialize DataTable after the component mounts and the data is fetched
    useEffect(() => {
        if (data) {
            // Initialize DataTable after data has been populated in the table
            $('#categorieslist_data').DataTable({
                destroy: true,  // Destroy any existing DataTable instances before reinitializing
                paging: true,   // Enable pagination
                searching: true, // Enable search functionality
                ordering: true,  // Enable column ordering
            });
        }
    }, [data]);  // Re-run effect when data changes



// Function to update a company
const updateCompanyStatus = (values) => {
     console.log("===========update company state===",values);
    const res = axios.put(`${apiUrl}/update-company-status/${values.companyId}`, values); // Update company endpoint
    //refetch()
    return res;
}


const statusMutation = useMutation({
    mutationFn: updateCompanyStatus,
    onSuccess: () => {
      queryClient.invalidateQueries(['companyList']); 
    },
  });

// Handle status change (active/inactive)
const handleStatusChange = (companyId, currentStatus) => {
    //console.log(companyId, '=====', currentStatus)
    const userConfirmed = window.confirm("Are you sure you want to proceed?");

    if (userConfirmed) {
        try {
            const newStatus = currentStatus === '0' ? 'inactive' : 'active'; // Toggle status
            statusMutation.mutate({ companyId, status: newStatus });
        } catch (error) {
          console.error('Error performing action:', error);
        }
      }

    
};




    return (


        <div className="main-wrapper">

            {/* Header Start  */}
            <Header />
            {/* Header End  */}

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-header">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <h4 className="page-title">Company<span className="count-title">-</span></h4>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="head-icons">
                                            <a href="contacts.html" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
                                            <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Collapse" id="collapse-header"><i className="ti ti-chevrons-up"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card main-card">
                                <div className="card-body">
                                    <div className="filter-section filter-flex">
                                        <div className="sortby-list">
                                            {/* <ul>

                                                <li>
                                                    <div className="form-wrap icon-form">
                                                        <span className="form-icon"><i className="ti ti-calendar"></i></span>
                                                        <input type="text" className="form-control bookingrange" placeholder />
                                                    </div>
                                                </li>

                                            </ul> */}
                                        </div>

                                        <div className="export-list text-sm-end">
                                            <ul>
                                                <li>
                                                    <div className="export-dropdwon">
                                                        <a href="javascript:void(0);" className="dropdown-toggle" data-bs-toggle="dropdown"><i className="ti ti-package-export"></i>Export</a>
                                                        <div className="dropdown-menu  dropdown-menu-end">
                                                            <ul>
                                                                <li>
                                                                    <a href="javascript:void(0);"><i className="ti ti-file-type-pdf text-danger"></i>Export as PDF</a>
                                                                </li>
                                                                <li>
                                                                    <a href="javascript:void(0);"><i className="ti ti-file-type-xls text-green"></i>Export as Excel </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);" className="btn btn-primary add-popup"><i className="ti ti-square-rounded-plus"></i>Add Company</a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>




                                    <div className="table-responsive custom-table">
                                        <table className="table table-striped table-bordered" id="categorieslist_data" style={{ width: "100%" }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Company Name</th>
                                                    <th>Email</th>
                                                    <th>Industry</th>
                                                    <th>Website</th>
                                                    <th>Phone</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {data?.data.data?.map(item => (
                                                    <tr key={item.pk_company_id}>
                                                        <td>{item.company_name}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.industry}</td>
                                                        <td>{item.website}</td>
                                                        <td>{item.phone}</td>
                                                        <td>
                                                            <button className={`btn btn-pill ${ item.is_active === '0' ? 'btn-danger' : 'btn-success' }`}
                                                                onClick={() => handleStatusChange(item.pk_company_id, item.is_active)}
                                                                >
                                                                {item.is_active === '0' ? 'InActive' : 'Active'}
                                                            </button>
                                                       </td>
                                                        <td>
                                                            <Button variant="primary" onClick={() => handleOpenModal(item)}>
                                                            <i class="ti ti-edit text-blue"></i> Edit
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="datatable-length"></div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="datatable-paginate"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="toggle-popup">
                <div className="sidebar-layout">
                    <div className="sidebar-header">
                        <h4>Add New Company</h4>
                        <a href="#" className="sidebar-close toggle-btn"><i className="ti ti-x"></i></a>
                    </div>
                    <div className="toggle-body">
                        <div className="pro-create">


                            <form onSubmit={formik.handleSubmit} >
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="col-form-label">Company Name<span className="text-danger">*</span></label>
                                            <input className="form-control" name="company_name"
                                                value={formik.values.company_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <small className="error">{formik.errors.company_name}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label className="col-form-label">Email  <span className="text-danger">*</span></label>
                                            </div>
                                            <input className="form-control" name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <small className="error">{formik.errors.email}</small>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Phone<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="phone"
                                                value={formik.values.phone}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <small className="error">{formik.errors.phone}</small>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Website <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="website"
                                                value={formik.values.website}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <small className="error">{formik.errors.website}</small>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Industry  <span className="text-danger"> *</span></label>
                                            <select class="form-control" name="industry" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.industry}>
                                                <option>Choose</option>
                                                <option value="Retail Industry">Retail Industry</option>
                                                <option value="Banking">Banking</option>
                                                <option value="Hotels">Hotels</option>
                                                <option value="Financial Services">Financial Services</option>
                                                <option value="Insurance">Insurance</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Language<span className="text-danger"> *</span></label>
                                            <select class="form-control" name="language" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.language}>
                                                <option>Choose</option>
                                                <option value="English">English</option>
                                                <option value="Arabic">Arabic</option>
                                                <option value="Chinese">Chinese</option>
                                                <option value="Hindi">Hindi</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Address <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="address"
                                                value={formik.values.address}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Country <span className="text-danger"> *</span></label>
                                            <select
                                                className="form-control"
                                                name="country"
                                                as="select"
                                                value={formik.values.country}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    formik.handleChange(e); // Update Formik's internal state
                                                    setSelectedCountry(e.target.value);
                                                }}
                                            >
                                                <option value="">Select Country</option>
                                                {countries && countries.map((country, cases) => (
                                                    <option key={country.pk_country_id} value={country.pk_country_id}>{country.country_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">State <span className="text-danger">*</span></label>

                                            <select
                                                className="form-control"
                                                name="state"
                                                value={formik.values.state}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    formik.handleChange(e); // Update Formik's internal state
                                                    setSelectedState(e.target.value);
                                                }}

                                                disabled={!selectedCountry} // Disables the dropdown if no country is selected
                                            >
                                                <option value="">Select State</option>
                                                {states.map((state) => (
                                                    <option key={state.pk_state_id} value={state.pk_state_id}>{state.state}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">City <span className="text-danger">*</span></label>
                                            <select disabled={!selectedState} className="form-control" name="city" as="select"
                                                value={formik.values.city}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    formik.handleChange(e); // Update Formik's internal state
                                                    setSelectedCity(e.target.value);
                                                }}>
                                                <option value="">Select City</option>
                                                {cities.map((city) => (
                                                    <option key={city.pk_city_id} value={city.pk_city_id}>{city.city_name}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Zipcode <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="zipcode"
                                                value={formik.values.zipcode}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Facebook <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="facebook"
                                                value={formik.values.facebook}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Linkedin <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="linkedin"
                                                value={formik.values.linkedin}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Twitter <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="twitter"
                                                value={formik.values.twitter}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Whatsapp <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="whatsapp"
                                                value={formik.values.whatsapp}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Instagram <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="instagram"
                                                value={formik.values.instagram}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>


                                </div>
                                <div className="d-flex align-items-center justify-content-end">
                                    <button type="button" data-bs-dismiss="offcanvas" className="btn btn-light me-2">Cancel</button>
                                    <button type="submit" className="btn btn-primary">Create </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div className="toggle-popup1">
                <div className="sidebar-layout">
                    <div className="sidebar-header">
                        <h4>Edit Contact</h4>
                        <a href="#" className="sidebar-close1 toggle-btn"><i className="ti ti-x"></i></a>
                    </div>
                    <div className="toggle-body">
                        <div className="pro-create">


                            <form onSubmit={edit_formik.handleSubmit}>
                                <input type="hidden1" name="edit_company_id" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_company_id} />

                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label className="col-form-label">Company Name<span className="text-danger">*</span></label>
                                            <input className="form-control" name="edit_company_name"
                                                value={edit_formik.values.edit_company_name}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                            <small className="error">{edit_formik.errors.edit_company_name}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label className="col-form-label">Email  <span className="text-danger">*</span></label>
                                            </div>
                                            <input className="form-control" name="edit_email"
                                                value={edit_formik.values.edit_email}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                            <small className="error">{edit_formik.errors.edit_email}</small>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Phone<span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_phone"
                                                value={edit_formik.values.edit_phone}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                            <small className="error">{edit_formik.errors.edit_phone}</small>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Website <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_website"
                                                value={edit_formik.values.edit_website}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                            <small className="error">{edit_formik.errors.edit_website}</small>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Industry  <span className="text-danger"> *</span></label>
                                            <select class="form-control" name="edit_industry" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_industry}>
                                                <option value="">Choose</option>
                                                <option value="Retail Industry" >Retail Industry</option>
                                                <option value="Banking">Banking</option>
                                                <option value="Hotels">Hotels</option>
                                                <option value="Financial Services">Financial Services</option>
                                                <option value="Insurance">Insurance</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Language<span className="text-danger"> *</span></label>
                                            <select class="form-control" name="edit_language" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_language}>
                                                <option>Choose</option>
                                                <option value="English">English</option>
                                                <option value="Arabic">Arabic</option>
                                                <option value="Chinese">Chinese</option>
                                                <option value="Hindi">Hindi</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Address <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_address"
                                                value={edit_formik.values.edit_address}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Country <span className="text-danger"> *</span></label>
                                            <select
                                                className="form-control"
                                                name="edit_country"
                                                as="select"
                                                value={edit_formik.values.edit_country}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    edit_formik.handleChange(e); // Update Edit_formik's internal state
                                                    setSelectedCountry(e.target.value);
                                                }}
                                            >
                                                <option value="">Select Country</option>
                                                {countries && countries.map((country, cases) => (
                                                    <option key={country.pk_country_id} value={country.pk_country_id}>{country.country_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">State <span className="text-danger">*</span></label>

                                            <select
                                                className="form-control"
                                                name="edit_state"
                                                value={edit_formik.values.edit_state}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    edit_formik.handleChange(e); // Update Edit_formik's internal state
                                                    setSelectedState(e.target.value);
                                                }}

                                                disabled={!selectedCountry} // Disables the dropdown if no country is selected
                                            >
                                                <option value="">Select State</option>
                                                {states.map((state) => (
                                                    <option key={state.pk_state_id} value={state.pk_state_id}>{state.state}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-wrap">
                                            <label className="col-form-label">City <span className="text-danger">*</span></label>
                                            <select disabled={!selectedState} className="form-control" name="edit_city" as="select"
                                                value={edit_formik.values.edit_city}
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    edit_formik.handleChange(e); // Update Edit_formik's internal state
                                                    setSelectedCity(e.target.value);
                                                }}>
                                                <option value="">Select City</option>
                                                {cities.map((city) => (
                                                    <option key={city.pk_city_id} value={city.pk_city_id}>{city.city_name}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Zipcode <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_zipcode"
                                                value={edit_formik.values.edit_zipcode}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Facebook <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_facebook"
                                                value={edit_formik.values.edit_facebook}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Linkedin <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_linkedin"
                                                value={edit_formik.values.edit_linkedin}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Twitter <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_twitter"
                                                value={edit_formik.values.edit_twitter}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Whatsapp <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_whatsapp"
                                                value={edit_formik.values.edit_whatsapp}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="mb-3">
                                            <label className="col-form-label">Instagram <span className="text-danger"> *</span></label>
                                            <input className="form-control" name="edit_instagram"
                                                value={edit_formik.values.edit_instagram}
                                                onChange={edit_formik.handleChange}
                                                onBlur={edit_formik.handleBlur}
                                            />
                                        </div>
                                    </div>


                                </div>
                                <div className="d-flex align-items-center justify-content-end">
                                    <button type="button" data-bs-dismiss="offcanvas" className="btn btn-light me-2">Cancel</button>

                                    <button type="submit" className="btn btn-primary"> {mutation.isLoading ? 'Updating...' : 'Update'} </button>
                                </div>
                                {/* Handle success or error */}
                                {mutation.isError && <div>Error updating form</div>}
                                {mutation.isSuccess && <div>Form updated successfully</div>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>






            <div className="modal custom-modal fade" id="create_contact" role="dialog" style={{ opacity: 1 }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 m-0 justify-content-end">
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ti ti-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="success-message text-center">
                                <div className="success-popup-icon bg-light-blue">
                                    <i className="ti ti-user-plus"></i>
                                </div>
                                <h3>Contact Created Successfully!!!</h3>
                                <p>View the details of contact, created</p>
                                <div className="col-lg-12 text-center modal-btn">
                                    <a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                                    {/* <a href="/contacts" re className="btn btn-primary">View Details</a> */}
                                    <button className="btn btn-primary">
                                        View Details
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal custom-modal fade" id="save_view" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add New View</h5>
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ti ti-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form action="contacts.html">
                                <div className="form-wrap">
                                    <label className="col-form-label">View Name</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="modal-btn text-end">
                                    <a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                                    <button type="submit" className="btn btn-danger">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal custom-modal fade" id="access_view" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Access For</h5>
                            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="ti ti-x"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form action="contacts.html">
                                <div className="form-wrap icon-form">
                                    <span className="form-icon"><i className="ti ti-search"></i></span>
                                    <input type="text" className="form-control" placeholder="Search" />
                                </div>
                                <div className="access-wrap">
                                    <ul>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-19.jpg`} alt />
                                                    <a href="#">Darlee Robertson</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-20.jpg`} alt />
                                                    <a href="#">Sharon Roy</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-21.jpg`} alt />
                                                    <a href="#">Vaughan</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-01.jpg`} alt />
                                                    <a href="#">Jessica</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs">
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-16.jpg`} alt />
                                                    <a href="#">Carol Thomas</a>
                                                </span>
                                            </label>
                                        </li>
                                        <li className="select-people-checkbox">
                                            <label className="checkboxs" >
                                                <input type="checkbox" />
                                                <span className="checkmarks"></span>
                                                <span className="people-profile">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/img/profiles/avatar-22.jpg`} alt />
                                                    <a href="#">Dawn Mercha</a>
                                                </span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="modal-btn text-end">
                                    <a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
                                    <button type="submit" className="btn btn-primary">Confirm</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Company;