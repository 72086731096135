import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Sample accordion component
const Accordion = ({ title, children, isOpen, toggle }) => (
  <div>
    <h3 onClick={toggle} style={{ cursor: 'pointer' }}>{title}</h3>
    {isOpen && <div>{children}</div>}
  </div>
);

// Validation schema using Yup
const validationSchema = Yup.object({
  section1: Yup.object({
    name: Yup.string().required('Name is required'),
  }),
  section2: Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
  }),
});

const AccordionForm = () => {
  const [openSection, setOpenSection] = React.useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <Formik
      initialValues={{
        section1: { name: '' },
        section2: { email: '' },
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ values }) => (
        <Form>
          <Accordion title="Section 1" isOpen={openSection === 'section1'} toggle={() => toggleSection('section1')}>
            <div>
              <label>Name</label>
              <Field name="section1.name" type="text" />
              <ErrorMessage name="section1.name" component="div" className="error" />
            </div>
          </Accordion>
          <Accordion title="Section 2" isOpen={openSection === 'section2'} toggle={() => toggleSection('section2')}>
            <div>
              <label>Email</label>
              <Field name="section2.email" type="email" />
              <ErrorMessage name="section2.email" component="div" className="error" />
            </div>
          </Accordion>
          <button type="submit">Submit</button>
        </Form>
      )}
    </Formik>
  );
};

export default AccordionForm;