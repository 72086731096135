import React, { useState, useEffect, useCallback, useRef, useContext  } from 'react';
import axios from 'axios';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useHref, useNavigate, Navigate } from 'react-router-dom';
import { Formik, useFormik, Form, Field, errors } from 'formik';
import Select from 'react-select';

import * as Yup from 'yup';
import Header from './Header'
import { contactsValidation } from './contactsValidation';

import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';

import $ from 'jquery';
import 'datatables.net-bs4';
import { differenceInYears } from 'date-fns';

import { ProfileContext } from '../context/ProfileContext'; // Import ProfileContext



const Profile = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    const [editContact, setEditContact] = useState([]);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [selectedFileAdd, setSelectedFileAdd] = React.useState(null);
    const [isVisible, setIsVisible] = useState(false);

    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState('');

    //const [profile_img, setProfileImage] = useState(localStorage.getItem('profile_img') || '');
    //const { profile_img, updateProfileImage } = useContext(ProfileContext);
    const { profileImage, updateProfileImage } = useContext(ProfileContext);




    const emp_id = localStorage.getItem('emp_id');
    

   // Fetch countries data
    useEffect(() => {

        axios.post(`${apiUrl}/countrylist`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setCountries(response.data.data);
                    
                } else {
                    console.error("Expected an array but got:", response);
                }
            })
            .then(data => console.log(data))
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);


    // Fetch states data based on selected country
    useEffect(() => {
        console.log("country id here ====================", selectedCountry)
        if (selectedCountry) {
            axios({
                method: 'POST',
                url: `${apiUrl}/statelist`,
                data: { country: `${selectedCountry}` }
            })
                .then(function (response) {
                    console.log(response)
                    if (Array.isArray(response.data.data)) {
                        setStates(response.data.data);
                        //setStates([]); // Reset cities when country changes
                    } else {
                        console.error("Expected an array but got:", response);
                    }
                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    }, [selectedCountry]);



    // Fetch cities data based on selected state
    useEffect(() => {
        console.log("state id here ====================", selectedState)
        if (selectedState) {
            axios({
                method: 'POST',
                url: `${apiUrl}/citylist`,
                data: { state: `${selectedState}` }
            })
                .then(function (response) {
                    console.log(response)
                    //alert('Successfully signed up!');
                    if (Array.isArray(response.data.data)) {
                        setCities(response.data.data);
                        //setStates([]); // Reset cities when country changes
                    } else {
                        console.error("Expected an array but got:", response);
                    }
                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    }, [selectedState]);




    const handleEdit = async (id) => {

        //alert(`Edit button clicked for ID: ${id}`);
        if (id) {
            await axios({
                method: 'POST',
                url: `${apiUrl}/employee-contact-edit`,
                data: { id: `${id}` }
            })
                .then(function (response) {
                    //alert(response.data.data.first_name);
                    setEditContact(response.data.data);
                })
                .catch(function (response) {
                    console.log(response)
                });
        }
    };

    useEffect(() => {
        console.log("=====Session Value 2222===========",emp_id);
        handleEdit(emp_id);
    }, []);

   
    useEffect(() => {
       
        if (editContact) {
            
            setSelectedCountry(editContact.country_id);
            setSelectedState(editContact.state_id);

            edit_formik.setValues({
                edit_profile_img: editContact.profile_img || '',
                hdn_edit_profile_img: editContact.profile_img || '',
                edit_pk_emp_id: editContact.pk_emp_id || '',
                edit_pk_emp_id1: editContact.pk_emp_id || '',
                edit_category_type: editContact.category_type || '',
                edit_first_name: editContact.first_name || '',
                edit_last_name: editContact.last_name || '',
                edit_dob: editContact.dob || '',
                edit_gender: editContact.gender || '',
                edit_phone: editContact.phone || '',
                edit_email: editContact.email || '',
                edit_address: editContact.address || '',
                edit_country: editContact.country_id || '',
                edit_state: editContact.state_id || '',
                edit_city: editContact.city_id || '',
                edit_job_title: editContact.job_title || '',
                emp_department: editContact.department || '',
                edit_location: editContact.location || '',
                edit_designation: editContact.designation || '',
                edit_work_address: editContact.work_address || '',
                edit_work_phone: editContact.work_phone || '',
                edit_work_email: editContact.work_email || '',
                edit_work_country: editContact.work_country || '',
                edit_work_state: editContact.work_state || '',
                edit_work_city: editContact.work_city || '',
                edit_linkedin_profile: editContact.linkedin_profile || '',
                edit_reporting_manager: editContact.reporting_manager || '',

                edit_marital_status: editContact.marital_status || '',

                edit_name_of_spouse: editContact.name_of_spouse || '',
                edit_spouse_dob: editContact.spouse_dob || '',
                edit_no_of_kids: editContact.no_of_kids || '',
                edit_spouse_anniversary: editContact.anniversary || '',
                edit_father_name: editContact.father_name || '',
                edit_mother_name: editContact.mother_name || '',
                edit_ea_name: editContact.ea_name || '',
                edit_pa_secretary_name: editContact.secretary_name || '',
                edit_pa_secretary_phone: editContact.secretary_phone || '',
                edit_secretary_phone: editContact.secretary_phone || '',
                edit_hobbies: editContact.hobbies || '',
                edit_favorite_sports: editContact.favorite_sports || '',
                edit_favorite_shows: editContact.favorite_shows || '',
                edit_favorite_brands: editContact.favorite_brands || '',
                edit_favorite_travel_destination: editContact.favorite_travel_destination || '',
                edit_other_interests: editContact.other_interests || '',
                edit_facebook: editContact.facebook || '',
                edit_twitter: editContact.twitter || '',
                edit_instagram: editContact.instagram || '',
                edit_other_relevant_social_media_platforms: editContact.other_relevant_social_media_platforms || '',
                edit_preferred_method_of_contact: editContact.preferred_method_of_contact || '',
                edit_pet_type: editContact.pet_type || '',
                edit_breed: editContact.pet_breed || '',
                edit_ailmments: editContact.health_parameters_ailmments || '',
                edit_allergies: editContact.health_parameters_allergies || '',
                edit_comments: editContact.additional_details_notes || '',

            });
        }
    }, [editContact]);

// Validation schema using Yup
const edit_schema = Yup.object({
    edit_first_name: Yup.string()
        .required('First name is required')
        .min(2, 'First name must be at least 2 characters'),
    edit_last_name: Yup.string().min(3).required('Last Name is required'),
    edit_dob: Yup.date()
        .nullable()
        .required("Date of birth is required"),

    edit_gender: Yup.string().required('Gender is required'),
    edit_phone: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(10, 'Must be exactly 10 digits')
        .max(10, 'Must be exactly 10 digits')
        .required('Mobile number is required'),
    edit_email: Yup.string().email('Invalid email address').required('Email is required'),
    edit_address: Yup.string().min(3).required('Address is required'),
    edit_country: Yup.string().required('Interests are required'),

});


// Formik setup
const edit_formik = useFormik({
    initialValues: {
        edit_profile_img                              : '',
        hdn_edit_profile_img                          : '',
        edit_pk_emp_id                                : '',
        edit_pk_emp_id1                               : '',
        edit_category_type                            : '',
        edit_first_name                               : '',
        edit_last_name                                : '',
        edit_dob                                      : '',
        edit_gender                                   : '',
        edit_phone                                    : '',
        edit_email                                    : '',
        edit_address                                  : '',
        edit_country                                  : '',
        edit_state                                    : '',
        edit_city                                     : '',
        edit_job_title                                : '',
        edit_department                               : '',
        edit_location                                 : '',
        edit_designation                              : '',
        edit_office_address                           : '',
        edit_work_phone                               : '',
        edit_work_email                               : '',
        edit_work_country                             : '',
        edit_work_state                               : '',
        edit_work_city                                : '',
        edit_linkedin_profile                         : '',
        edit_reporting_manager                        : '',

        edit_marital_status                           : '',
        edit_name_of_spouse                           : '',
        edit_no_of_kids                               : '',
        edit_spouse_anniversary                       : '',
        edit_father_name                              : '',
        edit_mother_name                              : '',
        edit_ea_name                                  : '',
        edit_pa_secretary_name                        : '',
        edit_pa_secretary_phone                       : '',
        edit_pa_secretary_email                       : '',
        edit_hobbies                                  : '',
        edit_favorite_sports                          : '',
        edit_favorite_shows                           : '',
        edit_favorite_brands                          : '',
        edit_favorite_travel_destination              : '',
        edit_other_interests                          : '',
        edit_facebook                                 : '',
        edit_twitter                                  : '',
        edit_instagram                                : '',
        edit_other_relevant_social_media_platforms    : '',
        edit_preferred_method_of_contact              : '',
        edit_pet_type                                 : [],
        edit_breed                                    : [],
        edit_ailmments                                : '',
        edit_allergies                                : '',
        edit_comments                                 : '',
    },
    validationSchema: edit_schema,
    onSubmit: (values) => {
        //alert('Form submitted!');

        console.log("===========dddddd=", values.profile_img);
        const formData = new FormData();
        formData.append("edit_profile_img", selectedFile);
        formData.append("hdn_edit_profile_img", values.hdn_edit_profile_img);

        formData.append("edit_pk_emp_id", values.edit_pk_emp_id);
        formData.append("edit_pk_emp_id1", values.edit_pk_emp_id1);
        formData.append("edit_category_type", values.edit_category_type);
        formData.append("edit_first_name", values.edit_first_name);
        formData.append("edit_last_name", values.edit_last_name);
        formData.append("edit_dob", values.edit_dob);
        formData.append("edit_gender", values.edit_gender);
        formData.append("edit_phone", values.edit_phone);
        formData.append("edit_email", values.edit_email);
        formData.append("edit_address", values.edit_address);
        formData.append("edit_country", values.edit_country);
        formData.append("edit_state", values.edit_state);
        formData.append("edit_city", values.edit_city);

        formData.append("edit_job_title", values.edit_job_title);
        formData.append("edit_department", values.edit_department);
        formData.append("edit_location", values.edit_location);
        formData.append("edit_designation", values.edit_designation);
        formData.append("edit_office_address", values.edit_office_address);
        formData.append("edit_work_phone", values.edit_work_phone);
        formData.append("edit_work_email", values.edit_work_email);
        formData.append("edit_work_country", values.edit_work_country);
        formData.append("edit_work_state", values.edit_work_state);
        formData.append("edit_work_city", values.edit_work_city);
        
        formData.append("edit_linkedin_profile", values.edit_linkedin_profile);
        formData.append("edit_reporting_manager", values.edit_reporting_manager);

        formData.append("edit_marital_status", values.edit_marital_status);
        formData.append("edit_name_of_spouse", values.edit_name_of_spouse);
        formData.append("edit_no_of_kids", values.edit_no_of_kids);

        formData.append("edit_spouse_dob", values.edit_spouse_dob);

        formData.append("edit_spouse_anniversary", values.edit_spouse_anniversary);
        formData.append("edit_father_name", values.edit_father_name);
        formData.append("edit_mother_name", values.edit_mother_name);
        formData.append("edit_ea_name", values.edit_ea_name);
        formData.append("edit_pa_secretary_name", values.edit_pa_secretary_name);
        formData.append("edit_pa_secretary_phone", values.edit_pa_secretary_phone);
        formData.append("edit_pa_secretary_email", values.edit_pa_secretary_email);

        formData.append("edit_hobbies", values.edit_hobbies);
        formData.append("edit_favorite_sports", values.edit_favorite_sports);
        formData.append("edit_favorite_shows", values.edit_favorite_shows);
        formData.append("edit_favorite_brands", values.edit_favorite_brands);
        formData.append("edit_favorite_travel_destination", values.edit_favorite_travel_destination);
        formData.append("edit_other_interests", values.edit_other_interests);
        formData.append("edit_facebook", values.edit_facebook);
        formData.append("edit_twitter", values.edit_twitter);
        formData.append("edit_instagram", values.edit_instagram);
        formData.append("edit_other_relevant_social_media_platforms", values.edit_other_relevant_social_media_platforms);

        formData.append("edit_preferred_method_of_contact", values.edit_preferred_method_of_contact);
        formData.append("edit_pet_type", values.edit_pet_type);
        formData.append("edit_breed", values.edit_breed);

        formData.append("edit_ailmments", values.edit_ailmments);
        formData.append("edit_allergies", values.edit_allergies);
        formData.append("edit_comments", values.edit_comments);

        console.log("===============" + values.edit_pk_emp_id1);
        axios({
            method: 'POST',
            url: `${apiUrl}/edit-employee-contact`,
            headers: { "Content-Type": "multipart/form-data" },
            //data: values
            data: formData,
        })
            .then(function (res) {
                console.log("image updated===",res.data.data.profile_img)
                //alert('Data was successfully updated.');

                localStorage.setItem('profile_img====>', res.data.data.profile_img);
                updateProfileImage(res.data.data.profile_img); // Update image globally
                

                $('.toggle-popup1').removeClass('sidebar-popup');
                $('.sidebar-popoverlay').removeClass('open');
                editDataTableRow(values.edit_pk_emp_id, res.data.data)

                
                // $("#preview1").show();
                setIsVisible(!isVisible); // Toggle visibility
            })
            .catch(function (res) {
                console.log(res,)
            });

    },
});

const editDataTableRow = (id, updatedData) => {
    // Get the DataTable instance
    const table = $('#categorieslist_data').DataTable();

    // Find the row that matches the id and update it
    const rowIndex = table.rows().eq(0).filter(function (idx) {
        return table.cell(idx, 0).data() == id; // Assumes first column is the ID
    });

    if (rowIndex.length > 0) {
        table.row(rowIndex).data(updatedData).draw(false); // Update the row data without pagination reset
    }
};


const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0])
    updateProfileImage(event.target.files[0]); // Update image globally
}

const setInputValue = useCallback(
    (key, value) =>
        edit_formik.setValues({
            ...edit_formik.values,
            [key]: value,
        }),
    [edit_formik]
);



    return (


        <div className="main-wrapper">

            {/* Header Start  */}
            <Header />
            {/* Header End  */}
    <div className="page-wrapper">
      <div className="content">

          <div className="card">
            <div className="row">
            <div className="col-xl-12 col-lg-12">
            <div className="card">
               <div className="card-body">
                  <h4 className="fw-semibold mb-3">Profile Settings</h4>
                  
                  <form onSubmit={edit_formik.handleSubmit}>
                  <input type="hidden" name="edit_pk_emp_id" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_pk_emp_id1} />

                     <div className="border-bottom mb-3 pb-3"> 
                        <h5 className="fw-semibold mb-1">Employee Information</h5>
                        {/* <p>Provide the information below</p> */}
                     </div>
                     <div className="mb-3">
                        <div className="profile-upload">
                           <div className="profile-upload-img">
                            <span><i className="ti ti-photo"></i></span>
                            <input type="hidden" name="hdn_edit_profile_img" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.hdn_edit_profile_img} />
                             <img src={`${apiUrl}/writable/uploads/emp_contact_profile/${edit_formik.values.edit_profile_img}`} alt="" className="preview1" style={{ display: edit_formik.values.edit_profile_img ? 'block' : 'none' }} />

                            <button type="button" className="profile-remove">
                            <i className="ti ti-x"></i>
                            </button>

                           </div>
                           <div className="profile-upload-content">
                              <label className="profile-upload-btn">
                              <i className="ti ti-file-broken"></i> Upload File
                              <input type="file" className="input-img" onChange={handleFileSelect} />
                              </label>
                              <p>JPG, GIF or PNG. Max size of 800K</p>
                           </div>
                        </div>
                     </div>
                     <div className="border-bottom mb-3">
                        <div className="row">
                            
                            <div className="col-md-6">
                                <div className="form-wrap">
                                    <label className="col-form-label">First Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" name="edit_first_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_first_name} />
                                    <small className="error">{edit_formik.errors.edit_first_name}</small>

                                </div>
                            </div>

                        <div className="col-md-6">
                            <div className="form-wrap">
                                <label className="col-form-label">Last Name <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="edit_last_name" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_last_name} />
                                <small className="error">{edit_formik.errors.edit_last_name}</small>

                            </div>
                        </div>

                        <div className="col-md-6">
                                <div className="form-wrap">
                                    <label className="col-form-label">Date of Birth <span className="text-danger">*</span></label>
                                    <input type="date" className="form-control" name="edit_dob" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_dob} />
                                    <small className="error">{edit_formik.errors.edit_dob}</small>

                                </div>
                            </div>

                        <div className="col-md-6">
                                    <div className="form-wrap">
                                        <label className="col-form-label">Gender <span className="text-danger">*</span></label>
                                        <select className="form-control" name="edit_gender" as="select" onChange={edit_formik.handleChange}   >
                                            <option value="">Select Gender</option>
                                            <option value="Male" selected={'Male' === editContact.gender}>Male</option>
                                            <option value="Female" selected={'Female' === editContact.gender}>Female</option>
                                        </select>
                                        <small className="error">{edit_formik.errors.edit_gender}</small>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-wrap">
                                        <label className="col-form-label">Phone <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" name="edit_phone" onChange={edit_formik.handleChange} onBlur={edit_formik.handleBlur} value={edit_formik.values.edit_phone} />
                                        <small className="error">{edit_formik.errors.edit_phone}</small>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-wrap">
                                        <label className="col-form-label">Email <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" name="edit_email" value={edit_formik.values.edit_email} onChange={edit_formik.handleChange} />
                                        <small className="error">{edit_formik.errors.edit_email}</small>
                                    </div>
                                </div>


                          


                        </div>
                     </div>
                     <div className="border-bottom mb-3 pb-3">
                        <h5 className="fw-semibold mb-1">Address</h5>
                        <p>Please enter the address details</p>
                     </div>
                     <div className="row">
                        <div className="col-md-12">
                            <div className="form-wrap">
                                <label className="col-form-label">Address <span className="text-danger">*</span></label>
                                <textarea class="form-control" id="client_address" rows="3" name="edit_address" value={edit_formik.values.edit_address} onChange={edit_formik.handleChange} ></textarea>
                                <small className="error">{edit_formik.errors.edit_address}</small>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-wrap">
                                <label className="col-form-label">Country <span className="text-danger">*</span></label>
                                <select className="form-control" name="edit_country" as="select" value={edit_formik.values.edit_country} onBlur={edit_formik.handleBlur} onChange={edit_formik.handleChange} >
                                    <option value="">Select Country</option>
                                    {countries && countries.map((country, cases) => (
                                        <option key={country.pk_country_id} value={country.pk_country_id}>{country.country_name}</option>
                                    ))}
                                </select>
                                <small className="error">{edit_formik.errors.edit_country}</small>
                            </div>
                        </div>


                        <div className="col-md-6">
                            <div className="form-wrap">
                                <label className="col-form-label">State <span className="text-danger">*</span></label>
                                <select className="form-control" name="edit_state" as="select" value={editContact.state_id} onChange={e => setSelectedState(e.target.value)}>
                                    <option value="">Select State</option>
                                    {states.map((state) => (
                                        <option key={state.pk_state_id} value={state.pk_state_id}>{state.state}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-wrap">
                                <label className="col-form-label">City <span className="text-danger">*</span></label>
                                <select disabled={!selectedState} className="form-control" name="edit_city" as="select" value={editContact.city_id} onChange={(e) => setInputValue('edit_city', e.target.value)}>
                                    <option value="">Select City</option>
                                    {cities.map((city) => (
                                        <option key={city.pk_city_id} value={city.pk_city_id}>{city.city_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                      </div>
                     <div>
                            <a href="#" className="btn btn-light me-2">Cancel</a>
                            <button type="submit" className="btn btn-primary">Update</button>
                        </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
        </div>

       </div>
      </div>
            
    </div>

  )
}

export default Profile;