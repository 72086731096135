import { QueryClient, QueryClientProvider } from '@tanstack/react-query';


import logo from './logo.svg';
import './App.css';
import HomeContainers from './containers/HomeContainers'
import ContactsContainers from './containers/ContactsContainers'
import ContactDetailsContainers from './containers/ContactDetailsContainers'
import LoginTestContainers from './containers/LoginTestContainers'
import AccordionFormContainers from './containers/AccordionFormContainers'
import EmployeeContact from './containers/EmployeeContainers'
import EmployeeContactDetails from './containers/EmployeeContactsDetailsContainers';
import EmployeeContactDataCSV from './containers/EmployeeContactDataCSVContainers';
import ClientContactDataCSV from './containers/ClientContactDataCSVContainers';
import Deals from './containers/DealsContainers';
import Products from './containers/ProductsContainers';
import ProductsCSVContainers from './containers/ProductsCSVContainers';
import LeadsContainers from './containers/LeadsContainers';
import Company from './containers/CompanyContainers';
import RolesPermissions from './containers/RolesPermissionsContainers';
import Permissions from './containers/PermissionsContainers';
import Dashboard from './containers/DashboardContainers';
import Profile from './containers/ProfileContainers';


import { ProfileProvider } from './context/ProfileContext';
import AccessControl from './containers/AccessControlContainers';
import { RoleProvider } from "./context/RoleContext";



import MultipleSelectDropdown from './containers/MultipleSelectDropdownContainers';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import LicenseValidator from './containers/LicenseValidatorContainers';



const queryClient = new QueryClient();

function App() {


  return (

<QueryClientProvider client={queryClient}>
  
<ProfileProvider> 
<RoleProvider> {/* Wrap everything needing RoleProvider */}
    <Routes>
      
      <Route path="/" element={<HomeContainers />} />
      <Route path="/license-key" element={<LicenseValidator />} />
      
      <Route path="/contacts" element={<ContactsContainers />} />
      <Route path="/contact-details" element={<ContactDetailsContainers />} />
      <Route path="/loging-test" element={<LoginTestContainers />} />
      <Route path="/accordion-form" element={<AccordionFormContainers />} />
      <Route path='/employee-contact' element={<EmployeeContact />} />
      <Route path='/employee-contact-details' element={<EmployeeContactDetails />} />
      <Route path='/employee-contact-data-csv' element={<EmployeeContactDataCSV />} />
      <Route path='/client-contact-data-csv' element={<ClientContactDataCSV />} />
      <Route path='/deals' element={<Deals />} />
      <Route path='/products' element={<Products />} />
      <Route path='/products-data-csv' element={<ProductsCSVContainers />} />
      <Route path='/leads' element={<LeadsContainers />} />
      <Route path='/company' element={<Company />} />
      <Route path='/roles-permissions' element={<RolesPermissions />} />
      <Route path='/permissions' element={<Permissions />} />
      
      { /*<Route path='/dashboard' element={<Dashboard />} /> */}

        <Route
              path="/dashboard"
              element={
                  <AccessControl moduleId={1} requiredAccess="READ">
                      <Dashboard />
                  </AccessControl>
              }
          />


      <Route path='/profile' element={<Profile />} />
      
      <Route path="/multiple-select-dropdown" element={<MultipleSelectDropdown />} />
      
      </Routes>

      </RoleProvider>
    </ProfileProvider>

</QueryClientProvider>

   
  );
}

export default App;
